import React, {useState} from 'react'
import Footer from '../Footer'
import Logo from '../../images/logo.png'
import User from '../../images/user.png'
import Password from '../../images/password.png'
import { useSelector, useDispatch } from "react-redux";
import { actionScreenUpdate, hideLoader, showLoader } from '../../redux/actions'
import axios from 'axios'
import '../../css/style.css'
import '../../css/bootstrap.min.css'
import {  useNavigate } from 'react-router-dom'
import { useAPI } from '../../Api/context'

function Login() {
  const {loginApi}=useAPI();
  const [username,setUsername] = useState('')
  const [password,setPassword] = useState('')
  const [Remember,setRemember] = useState(false)
  const [error,setError] = useState({ usernameError: false, passwordError: false })

  const handleChangeUserName = (e) => { setUsername(e.target.value.trim()) }

  const handleChangePassword = (e) => { setPassword(e.target.value.trim()) }

  const handleChangeRemember = (e) => { setRemember(e.target.checked) }

  const login = () => {
    if(username === '') {
      setError({
        usernameError: true,
        passwordError: false
      })
      return;
    } else if(password === '') {
      setError({
        usernameError: false,
        passwordError: true
      })
      return;
    } else if(password.length < 6) {
      setError({
        usernameError: false,
        passwordError: true
      })
      return;
    }
     else {
       setError({
         usernameError: false,
         passwordError: false
        })
        const data={
          username: username,
          password: password,
        }
       const response=loginApi(data);
       response.then((responseData) => {
         if (responseData===true) {
          localStorage.setItem('logged',true);
         } else {
           alert(responseData);
         }
       }
       )

    }
  }

  return (
    <div className="container-fluid h-100 login-form bg d-flex justify-content-center D-login p-0">
      <div className="box align-items-center">
        <div className="box--half1 text-center bg">
          <a><img src={Logo} className="logo-login-1" alt="logo" /></a>
        </div>
        <div className="box--half2 text-center h-100 login-bg">
          <div className="content-box">
            <div className="content-inner">
              <a><img src={Logo} className="logo-login d-none" alt="logo" /></a>
              <h1 className="heading-text mb-4">Welcome</h1>
              <h1 className="heading-text mb-4">To</h1>
              <h1 className="heading-text mb-5">Ferguson Cleaning Solutions Portal</h1>
              {/* <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type</p> */}
              <form>
                <div className="input-group has-validation">
                  <span className="input-group-text" id="basic-addon1">
                    <img src={User} className="user-icon" alt="icon" />
                  </span>
                  <input onChange={handleChangeUserName} type="email" className={`form-control ${error.usernameError ? 'is-invalid' : null}`} placeholder="Username (email)" aria-label="Username" aria-describedby="basic-addon1" />
                  {error.usernameError ? 
                  <div className="invalid-feedback">
                    Please enter the username(email).
                  </div> : null}
                </div>
                <div className="input-group has-validation">
                  <span className="input-group-text" id="basic-addon1">
                    <img src={Password} className="password-icon" alt="icon" />
                  </span>
                  <input onChange={handleChangePassword} type="password" className={`form-control ${error.passwordError ? 'is-invalid' : null}`} placeholder="Password" aria-label="Password" aria-describedby="basic-addon1" />
                  {error.passwordError ? 
                  <div className="invalid-feedback">
                    Please enter the password with atleat 6 digits.
                  </div> : null}
                </div>
                <div className="mb-3 mt-3 form-check forget">
                <div>
                  <input onChange={handleChangeRemember} type="checkbox" className="form-check-input" id="exampleCheck1" />
                  <label className="form-check-label m-1 remb" htmlFor="exampleCheck1">Remember me</label>
                </div>
                <div><a className="link pull-right m-1">Forgot password?</a></div>                  
                </div>
                <a className="btn m-0 mt-2" onClick={() => login()}>Login</a>
              </form>
            </div>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  )
}

export default Login