/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { hideLoader, showLoader } from "../../redux/actions";
import { useDispatch } from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { JOB_COMPONENT, createSchedule } from "./utils";
import { useAPI } from "../../Api/context";
import AlertModal from "../AlertModel/index";
import moment from "moment";
import { Form } from "react-bootstrap";

const NewJob = (props) => {
  const { setComponent, selectDate } = props.data;
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const initialvalue = {
    title: "",
    discription: "",
    jobType: "One Off Job",
    start: moment(selectDate).format("YYYY-MM-DD"),
    end: moment(selectDate).format("YYYY-MM-DD"),
    startTime: "09:00",
    endTime: "17:00",
    jobFor: [],
    jobBy: "",
    street: "",
    city: "",
    landMark: "",
    postalCode: "",
    jobNumber: "001",
  };
  const initialerror = {
    title: false,
    discription: false,
    start: false,
    end: false,
    startTime: false,
    endTime: false,
    jobFor: false,
    jobBy: false,
    street: false,
    city: false,
    landMark: false,
    postalCode: false,
    maxhours: false,
    weekselect: false,
  };
  const initialRecurringWeek = {
    monday: false,
    tuesday: false,
    wednesday: false,
    thursday: false,
    friday: false,
    saturday: false,
    sunday: false,
    all: false,
  };

  const { addJob } = useAPI();
  const [show, setShow] = useState(false);
  const [alertShow, setAlertShow] = useState(false);
  const [clientName, setClientName] = useState("Client Name");
  const [checkedData, setCheckedData] = useState([]);
  const [data, setData] = useState({});
  const [employees, setEmployees] = useState([]);
  const [clients, setClients] = useState([]);
  const [error, setError] = useState(initialerror);
  const [jobDetails, setJobDetails] = useState(initialvalue);
  const [page, setPage] = useState(1);
  const [pageData, setPageData] = useState([]);
  const [clientPageData, setClientPageData] = useState([]);
  const [recurringWeek, setRecurringWeek] = useState(initialRecurringWeek);
  const [value, setValue] = useState(480);
  const dispatch = useDispatch();
  const containerRef = useRef(null);
  let debounceTimer;

  const debounce = (callback, time) => {
    window.clearTimeout(debounceTimer);
    debounceTimer = window.setTimeout(callback, time);
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleChange = (e) => {
    setJobDetails((data) => ({ ...data, [e.target.name]: e.target.value }));
  };

  const handleSave = () => {
    setError(initialerror);
    let {
      title,
      discription,
      start,
      startTime,
      jobBy,
      street,
      city,
      landMark,
      postalCode,
      end,
      endTime,
      jobNumber,
    } = jobDetails;

    const jobForData = checkedData.map((data) => data.email);
    const jobForNameData = checkedData.map((data) => data.companyName);
    let areEqual = true;
    for (let key in recurringWeek) {
      if (recurringWeek[key] !== initialRecurringWeek[key]) {
        areEqual = false;
        break;
      }
    }
    if (jobBy === "") {
      setError((data) => ({ ...data, jobBy: true }));
      containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    } else if (title === "") {
      setError((data) => ({ ...data, title: true }));
      containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    } else if (discription === "") {
      setError((data) => ({ ...data, discription: true }));
      containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    } else if (street === "") {
      setError((data) => ({ ...data, street: true }));
      containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    }
    // else if (landMark === "") {
    //   setError((data) => ({ ...data, landMark: true }));
    //   containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    // } else if (city === "") {
    //   setError((data) => ({ ...data, city: true }));
    //   containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    // } else if (postalCode === "") {
    //   setError((data) => ({ ...data, postalCode: true }));
    //   containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    // }
    else if (start === "") {
      setError((data) => ({ ...data, start: true }));
    } else if (value === 0 || value === "0") {
      setError((data) => ({ ...data, maxhours: true }));
    } else if (end === "") {
      setError((data) => ({ ...data, end: true }));
    } else if (startTime === "") {
      setError((data) => ({ ...data, startTime: true }));
    } else if (endTime === "") {
      setError((data) => ({ ...data, endTime: true }));
    } else if (jobForData.length === 0) {
      setError((data) => ({ ...data, jobFor: true }));
    } else if (jobDetails.jobType === "Recurring Job" && areEqual === true) {
      setError((data) => ({ ...data, weekselect: true }));
    } else {
      setError(initialerror);
      const startDateTime = moment(`${start} ${startTime}`, "YYYY-MM-DD HH:mm");
      const formatedStart = startDateTime.format("YYYY-MM-DDTHH:mm");
      const endDateTime = moment(`${end} ${endTime}`, "YYYY-MM-DD HH:mm");
      const formatedEnd = endDateTime.format("YYYY-MM-DDTHH:mm");
      jobDetails["start"] = formatedStart;
      jobDetails["end"] = formatedEnd;
      let schedule = [
        {
          ...jobDetails,
          jobFor: jobForData,
          employeeName: jobForNameData,
          maxhours: rangeToTime(value),
          recurrringId: jobNumber,
        },
      ];
      if (jobDetails.jobType === "Recurring Job") {
        schedule = createSchedule(
          {
            ...jobDetails,
            jobFor: jobForData,
            employeeName: jobForNameData,
            maxhours: rangeToTime(value),
            recurrringId: jobNumber,
          },
          recurringWeek
        );
      }
      const response = addJob(schedule);
      response.then((responseData) => {
        if (responseData === true) {
          setData({
            ...data,
            header: "New Job",
            body: "Job Added Succesfully",
            reload: true,
          });
          setAlertShow(true);
        } else {
          setData((data) => ({
            ...data,
            header: "Failed!",
            body: responseData,
          }));
          setAlertShow(true);
        }
      });
    }
  };

  const getEmployee = async (searchValue) => {
    let url = `${REACT_APP_API_URL}employees/get_employees`;
    if (searchValue)
      url = `${REACT_APP_API_URL}employees/get_employees/${searchValue}`;
    await axios
      .get(url)
      .then((response) => {
        setEmployees(response.data.list);
        const data = response.data.list.filter((data, i) => {
          const pageStart = 0;
          const endPage = 6;
          if (i < endPage && i >= pageStart) {
            return data;
          }
        });
        setPageData(data);
      })
      .catch((err) => console.log(err.message));
  };

  const getClient = async (searchValue) => {
    let url = `${REACT_APP_API_URL}clients/get_clients`;
    if (searchValue)
      url = `${REACT_APP_API_URL}clients/get_clients/${searchValue}`;
    await axios
      .get(url)
      .then((response) => {
        setClients(response.data.list);
        const clientData = response.data.list.filter((data, i) => {
          const pageStart = 0;
          const endPage = 6;
          if (i < endPage && i >= pageStart) {
            return data;
          }
        });
        setClientPageData(clientData);
      })
      .catch((err) => console.log(err.message));
  };

  const getJobNumber = async () => {
    await axios
      .get(`${REACT_APP_API_URL}jobs/get_all_jobs`)
      .then((response) => {
        let number;
        if (response.data.list.length > 0) {
          number = response.data.list[0].id + 1;
          number =
            number < 10 ? `00${number}` : number < 100 ? `0${number}` : number;
          setJobDetails((data) => ({ ...data, jobNumber: number }));
        }
      })
      .catch((err) => console.log(err.message));
  };

  const showEmployeeModel = () => {
    changePage(1);
    setShow(true);
    setData({
      header: "Select Team",
      data: "employee",
    });
  };

  const AddClientName = () => {
    setShow(true);
    setData({
      header: "Select Client",
      data: "client",
    });
  };

  const changePage = (pageNumber, data) => {
    const pageLimit = 6;
    if (data === "employee") {
      if (
        pageNumber > 0 &&
        pageNumber <= Math.floor(employees.length / pageLimit) + 1 &&
        employees
      ) {
        setPage(pageNumber);
        const filteredData = employees.filter((data, i) => {
          const pageStart = (pageNumber - 1) * pageLimit;
          const endPage = pageLimit * pageNumber;
          if (i < endPage && i >= pageStart) {
            return data;
          }
        });
        setPageData(filteredData);
      }
    } else if (data === "client") {
      if (
        pageNumber > 0 &&
        pageNumber <= Math.floor(clients.length / pageLimit) + 1 &&
        clients
      ) {
        setPage(pageNumber);
        const filteredData = clients.filter((data, i) => {
          const pageStart = (pageNumber - 1) * pageLimit;
          const endPage = pageLimit * pageNumber;
          if (i < endPage && i >= pageStart) {
            return data;
          }
        });
        setClientPageData(filteredData);
      }
    }
  };

  const handleCheckboxChange = (e, item) => {
    if (e.target.checked) {
      setCheckedData([...checkedData, item]);
    } else {
      setCheckedData(checkedData.filter((d) => d.id !== item.id));
    }
  };

  const searchEmployee = (e) => {
    debounce(() => {
      if (e.target.value === "") {
        getEmployee();
      } else {
        getEmployee(e.target.value);
      }
    }, 500);
  };

  const searchClient = (e) => {
    debounce(() => {
      if (e.target.value === "") {
        getClient();
      } else {
        getClient(e.target.value);
      }
    }, 500);
  };

  const handleWeekSelect = (e) => {
    const { name, value } = e.target;
    if (name === "all") {
      if (value === "false") {
        setRecurringWeek({
          monday: true,
          tuesday: true,
          wednesday: true,
          thursday: true,
          friday: true,
          saturday: true,
          sunday: true,
          all: true,
        });
      } else {
        setRecurringWeek(initialRecurringWeek);
      }
    } else {
      setRecurringWeek((data) => ({
        ...data,
        [name]: !data[name],
        all: false, // Uncheck "all" checkbox when any other checkbox is changed
      }));
    }
  };

  useEffect(() => {
    dispatch(showLoader());
    getEmployee();
    getClient();
    getJobNumber();
    dispatch(hideLoader());
  }, []);

  function rangeToTime(value) {
    const hours = Math.floor(value / 60);
    const minutes = value % 60;
    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
  }
  function handleRangeChange(event) {
    setValue(event.target.value);
  }
  return (
    <div className="container  mt-0 " ref={containerRef}>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12">
            <h2 className="page-heading client-heading mb-4">JOB DETAILS</h2>
          </div>

          <div className="col-md-12">
            <div className="custom-heading d-flex justify-content-start align-items-center">
              <h4>Job Number:</h4>
              <span className="text-success">{jobDetails.jobNumber}</span>
            </div>
          </div>

          <div className="col-md-12">
            <div className="custom-heading d-flex justify-content-start align-items-center">
              <h4>Job By:</h4>
              <span>{clientName}</span>
              <i
                className="bi bi-plus-square"
                onClick={AddClientName}
                style={{
                  fontSize: "20px",
                  color: "#7fbc26",
                  alignSelf: "flex-end",
                  paddingBottom: "10px",
                  cursor: "pointer",
                }}
              ></i>
            </div>
            {error.jobBy ? (
              <div
                className="alert alert-danger alert-dismissible fade show h6"
                role="alert"
              >
                <strong>Error!</strong> Please add the Client Name.
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setError(initialerror)}
                ></button>
              </div>
            ) : null}
          </div>

          <div className="col-md-12 mb-3">
            <div className="custom-heading d-flex justify-content-start align-items-center flex-wrap">
              <h4>Job Type:</h4>
              <div className="form-check col-md-3 d-flex justify-content-start gap-5">
                <label className="form-check-label" htmlFor="inlineRadio1">
                  One Time Job
                </label>
                <input
                  className="form-check-input"
                  name="jobType"
                  onChange={handleChange}
                  type="radio"
                  id="inlineRadio1"
                  value="One Off Job"
                  checked={jobDetails.jobType === "One Off Job"}
                />
              </div>
              <div className="form-check col-md-3 d-flex justify-content-start gap-5">
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Recurring Job
                </label>
                <input
                  className="form-check-input"
                  type="radio"
                  name="jobType"
                  onChange={handleChange}
                  id="inlineRadio2"
                  value="Recurring Job"
                  checked={jobDetails.jobType === "Recurring Job"}
                />
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="input-group d-block">
              <textarea
                className="form-control w-100"
                rows="1"
                name="title"
                onChange={handleChange}
                aria-label="With textarea"
                placeholder="Title"
              ></textarea>
              {error.title ? (
                <div
                  className="alert alert-danger alert-dismissible fade show h6"
                  role="alert"
                >
                  <strong>Error!</strong> Please add the Title.
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setError(initialerror)}
                  ></button>
                </div>
              ) : null}
              <textarea
                className="form-control w-100"
                aria-label="With textarea"
                name="discription"
                onChange={handleChange}
                placeholder="Instructions"
                rows="4"
              ></textarea>
              {error.discription ? (
                <div
                  className="alert alert-danger alert-dismissible fade show h6"
                  role="alert"
                >
                  <strong>Error!</strong> Please add the Instructions.
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="alert"
                    aria-label="Close"
                    onClick={() => setError(initialerror)}
                  ></button>
                </div>
              ) : null}
            </div>
          </div>

          {/* <div className="col-md-6">
            <input
              onChange={handleChange}
              name="street"
              type="text"
              className="form-control"
              placeholder="Street"
              aria-label=""
            />
            {error.street ? (
              <div
                className="alert alert-danger alert-dismissible fade show h6"
                role="alert"
              >
                <strong>Error!</strong> Please add the Street of the Job.
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setError(initialerror)}
                ></button>
              </div>
            ) : null}
          </div><div className="col-md-6">
            <input
              onChange={handleChange}
              name="street"
              type="text"
              className="form-control"
              placeholder="Street"
              aria-label=""
            />
            {error.street ? (
              <div
                className="alert alert-danger alert-dismissible fade show h6"
                role="alert"
              >
                <strong>Error!</strong> Please add the Street of the Job.
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setError(initialerror)}
                ></button>
              </div>
            ) : null}
          </div>

          <div className="col-md-6">
            <input
              onChange={handleChange}
              name="landMark"
              type="text"
              className="form-control"
              placeholder="Land Mark"
              aria-label=""
            />
            {error.landMark ? (
              <div
                className="alert alert-danger alert-dismissible fade show h6"
                role="alert"
              >
                <strong>Error!</strong> Please add the Land Mark of The Job.
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setError(initialerror)}
                ></button>
              </div>
            ) : null}
          </div>

          <div className="col-md-6">
            <input
              onChange={handleChange}
              name="city"
              type="text"
              className="form-control"
              placeholder="City"
              aria-label="First name"
            />
            {error.city ? (
              <div
                className="alert alert-danger alert-dismissible fade show h6"
                role="alert"
              >
                <strong>Error!</strong> Please add the City of the Job.
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setError(initialerror)}
                ></button>
              </div>
            ) : null}
          </div>

          <div className="col-md-6">
            <input
              onChange={handleChange}
              name="postalCode"
              type="text"
              className="form-control"
              placeholder="Postal Code"
              id="inputEmail3"
            />
            {error.postalCode ? (
              <div
                className="alert alert-danger alert-dismissible fade show h6"
                role="alert"
              >
                <strong>Error!</strong> Please add the Postal Code of the Job.
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setError(initialerror)}
                ></button>
              </div>
            ) : null}
          </div> */}

          <div className="col-md-12">
            <input
              type="text"
              className="form-control"
              placeholder="Address"
              value={jobDetails.street}
              disabled
            />
            {/* {error.street ? (
              <div
                className="alert alert-danger alert-dismissible fade show h6"
                role="alert"
              >
                <strong>Error!</strong> Please add the Street of the Job.
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="alert"
                  aria-label="Close"
                  onClick={() => setError(initialerror)}
                ></button>
              </div>
            ) : null} */}
          </div>

          <div className="col-md-12 mb-4">
            <div className="card custom-row-1">
              <div
                className="card-header d-flex justify-content-between custom-heading-3 align-items-center"
                style={{ backgroundColor: "#EEEBEB" }}
              >
                <h5 className="opacity-75">Schedule</h5>
              </div>
              <div className="card-body">
                <div className="custom-date-container d-flex gap-3 flex-wrap justify-content-around">
                  <div className="d-flex justify-content-around gap-2">
                    <div className="custom-start-date">
                      <label className="d-block">Start Date</label>
                      <input
                        name="start"
                        onChange={handleChange}
                        type="date"
                        className="custom-date start"
                        min={moment(selectDate).format("YYYY-MM-DD")}
                        defaultValue={moment(selectDate).format("YYYY-MM-DD")}
                      />
                      {error.start ? (
                        <div
                          className="alert alert-danger alert-dismissible fade show h6"
                          role="alert"
                        >
                          <strong>Error!</strong> Please add Start Date.
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setError(initialerror)}
                          ></button>
                        </div>
                      ) : null}
                    </div>
                    <div className="custom-start-date">
                      <label className="d-block">Start Time</label>
                      <input
                        name="startTime"
                        onChange={handleChange}
                        type="time"
                        className="custom-date start"
                        defaultValue={"09:00"}
                      />
                      {error.startTime ? (
                        <div
                          className="alert alert-danger alert-dismissible fade show h6 position-absolute"
                          role="alert"
                        >
                          <strong>Error!</strong> Please add Start Time.
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setError(initialerror)}
                          ></button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="d-flex justify-content-around gap-2">
                    <div className="custom-end-date">
                      <label className="d-block">End Date</label>
                      <input
                        name="end"
                        onChange={handleChange}
                        type="date"
                        className="custom-date end"
                        min={moment(
                          jobDetails.start ? jobDetails.start : selectDate
                        ).format("YYYY-MM-DD")}
                        defaultValue={moment(selectDate).format("YYYY-MM-DD")}
                      />
                      {error.end ? (
                        <div
                          className="alert alert-danger alert-dismissible fade show h6 position-absolute"
                          role="alert"
                        >
                          <strong>Error!</strong> Please add End Date.
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setError(initialerror)}
                          ></button>
                        </div>
                      ) : null}
                    </div>
                    <div className="custom-end-date">
                      <label className="d-block">End Time</label>
                      <input
                        name="endTime"
                        onChange={handleChange}
                        type="time"
                        className="custom-date end"
                        defaultValue={"17:00"}
                      />
                      {error.endTime ? (
                        <div
                          className="alert alert-danger alert-dismissible fade show h6 position-absolute"
                          role="alert"
                        >
                          <strong>Error!</strong> Please add End Time.
                          <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="alert"
                            aria-label="Close"
                            onClick={() => setError(initialerror)}
                          ></button>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 d-flex flex-direction-column flex-wrap">
                  <div className="col-md-12 mt-2 mb-0 d-flex flex-wrap">
                    <label className="opacity-75">
                      Max Hours(HH:MM):{" "}
                      <span className="fw-bolder">{rangeToTime(value)}</span>
                    </label>
                    <Form.Range
                      id="time-range"
                      name="time-range"
                      min="0"
                      max="1440"
                      step="15"
                      value={value}
                      onChange={handleRangeChange}
                    />
                  </div>
                  {error.maxhours ? (
                    <div
                      className="alert alert-danger alert-dismissible fade show h6 position-absolute"
                      role="alert"
                    >
                      <strong>Error!</strong> Please add Max Hours.
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => setError(initialerror)}
                      ></button>
                    </div>
                  ) : null}
                </div>
                {jobDetails.jobType === "Recurring Job" && (
                  <div>
                    <div className="col-md-12 mb-3 mt-3 d-flex gap-4 flex-wrap">
                      <div className="d-flex gap-1 align-items-center">
                        <input
                          name="monday"
                          onChange={handleWeekSelect}
                          checked={recurringWeek.monday}
                          value={recurringWeek.monday}
                          type="checkbox"
                          id="monday"
                          className="form-checkbox form"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <label htmlFor="monday" className="d-block">
                          M
                        </label>
                      </div>
                      <div className="d-flex gap-1 align-items-center">
                        <input
                          name="tuesday"
                          onChange={handleWeekSelect}
                          checked={recurringWeek.tuesday}
                          value={recurringWeek.tuesday}
                          type="checkbox"
                          id="tuesday"
                          className="form-checkbox form"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <label htmlFor="tuesday" className="d-block">
                          Tu
                        </label>
                      </div>
                      <div className="d-flex gap-1 align-items-center">
                        <input
                          name="wednesday"
                          onChange={handleWeekSelect}
                          checked={recurringWeek.wednesday}
                          value={recurringWeek.wednesday}
                          type="checkbox"
                          id="wednesday"
                          className="form-checkbox form"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <label htmlFor="wednesday" className="d-block">
                          W
                        </label>
                      </div>
                      <div className="d-flex gap-1 align-items-center">
                        <input
                          name="thursday"
                          onChange={handleWeekSelect}
                          checked={recurringWeek.thursday}
                          value={recurringWeek.thursday}
                          type="checkbox"
                          id="thursday"
                          className="form-checkbox form"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <label htmlFor="thursday" className="d-block">
                          Th
                        </label>
                      </div>
                      <div className="d-flex gap-1 align-items-center">
                        <input
                          name="friday"
                          onChange={handleWeekSelect}
                          checked={recurringWeek.friday}
                          value={recurringWeek.friday}
                          type="checkbox"
                          id="friday"
                          className="form-checkbox form"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <label htmlFor="friday" className="d-block">
                          F
                        </label>
                      </div>
                      <div className="d-flex gap-1 align-items-center">
                        <input
                          name="saturday"
                          onChange={handleWeekSelect}
                          checked={recurringWeek.saturday}
                          value={recurringWeek.saturday}
                          type="checkbox"
                          id="saturday"
                          className="form-checkbox form"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <label htmlFor="saturday" className="d-block">
                          Sa
                        </label>
                      </div>
                      <div className="d-flex gap-1 align-items-center">
                        <input
                          name="sunday"
                          onChange={handleWeekSelect}
                          checked={recurringWeek.sunday}
                          value={recurringWeek.sunday}
                          type="checkbox"
                          id="sunday"
                          className="form-checkbox form"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <label htmlFor="sunday" className="d-block">
                          Su
                        </label>
                      </div>
                      <div className="d-flex gap-1 align-items-center">
                        <input
                          name="all"
                          onChange={handleWeekSelect}
                          checked={recurringWeek.all}
                          value={recurringWeek.all}
                          type="checkbox"
                          id="all"
                          className="form-checkbox form"
                          style={{ width: "20px", height: "20px" }}
                        />
                        <label htmlFor="all" className="d-block">
                          All
                        </label>
                      </div>
                    </div>
                    {error.weekselect ? (
                      <div
                        className="alert alert-danger alert-dismissible fade show h6 position-absolute"
                        role="alert"
                      >
                        <strong>Error!</strong> Please add Reccuring week.
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError(initialerror)}
                        ></button>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="card custom-row-1">
              <div
                className="card-header d-flex justify-content-between custom-heading-4 align-items-center"
                style={{ backgroundColor: "#EEEBEB" }}
              >
                <h5 className="opacity-75">Team</h5>
                <button
                  className="btn btn-outline-success"
                  onClick={showEmployeeModel}
                >
                  + Assign
                </button>
              </div>
              <div className="card-body">
                {checkedData.length > 0 ? (
                  <p className="col-12">
                    {checkedData.map((data, i) => {
                      return (
                        <button
                          type="button"
                          className="close border border-light rounded col-3"
                          aria-label="Close"
                        >
                          {data.first_name}
                          <span
                            onClick={() => {
                              setCheckedData(
                                checkedData.filter((d) => d.id !== data.id)
                              );
                            }}
                            aria-hidden="true"
                          >
                            &times;
                          </span>
                        </button>
                      );
                    })}
                  </p>
                ) : (
                  <>
                    <p
                      className="text-muted"
                      style={{
                        fontSize: "15px",
                        fontWeight: 400,
                        fontStyle: "italic",
                      }}
                    >
                      No users are currently assigned
                    </p>
                    {error.jobFor ? (
                      <div
                        className="alert alert-danger alert-dismissible fade show h6 position-absolute"
                        role="alert"
                      >
                        <strong>Error!</strong> Please add Client Name.
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError(initialerror)}
                        ></button>
                      </div>
                    ) : null}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-12 text-center d-flex justify-content-evenly">
            <div className="col-6 d-flex justify-content-center">
              <button
                className="btn text-white btn-primary add mt-4 mb-4"
                onClick={() => setComponent(JOB_COMPONENT.initial)}
              >
                Calender
              </button>
            </div>
            <div className="col-6 d-flex justify-content-center">
              <button
                className="btn btn-outline-success text-black text-white btn-primary add mt-4 mb-4"
                id="btn-save"
                onClick={handleSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{data.header}</Modal.Title>
        </Modal.Header>
        {data.data === "employee" && (
          <Modal.Body>
            <div className="list-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={searchEmployee}
              />
              {pageData.map((data, i) => {
                return (
                  <label
                    className="custom-list list-group-item list-group-item-action m-2 d-flex justify-content-start align-items-center"
                    key={data.id}
                  >
                    <input
                      type="checkbox"
                      checked={checkedData.some((d) => d.id === data.id)}
                      onChange={(e) => handleCheckboxChange(e, data)}
                    />
                    {data.email}
                  </label>
                );
              })}
            </div>
          </Modal.Body>
        )}
        {data.data === "client" && (
          <Modal.Body>
            <div className="list-group">
              <input
                type="text"
                className="form-control"
                placeholder="Search"
                onChange={searchClient}
              />
              {clientPageData.map((data, i) => {
                return (
                  <label
                    onClick={() => {
                      setClientName(data.companyName);
                      setJobDetails((jobDetails) => ({
                        ...jobDetails,
                        jobBy: data.email,
                        street: data.address,
                      }));
                      setShow(false);
                    }}
                    className="custom-list list-group-item list-group-item-action m-2"
                  >
                    {data.email}
                  </label>
                );
              })}
            </div>
          </Modal.Body>
        )}
        <nav aria-label="Page navigation example ">
          <ul className="pagination justify-content-center">
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => changePage(page - 1, data.data)}
                aria-label="Previous"
              >
                <span aria-hidden="true">&laquo;</span>
              </a>
            </li>
            <li className="page-item" onClick={() => changePage(1, data.data)}>
              <a className="page-link">1</a>
            </li>
            <li className="page-item" onClick={() => changePage(2, data.data)}>
              <a className="page-link">2</a>
            </li>
            <li className="page-item" onClick={() => changePage(3, data.data)}>
              <a className="page-link">3</a>
            </li>
            <li className="page-item">
              <a
                className="page-link"
                onClick={() => changePage(page + 1, data.data)}
                aria-label="Next"
              >
                <span aria-hidden="true">&raquo;</span>
              </a>
            </li>
          </ul>
        </nav>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <AlertModal data={{ ...data, show: alertShow, setShow: setAlertShow }} />
    </div>
  );
};

export default NewJob;
