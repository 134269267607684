import React from "react";
import { useSelector } from "react-redux";
import ClockLoader from "react-spinners/ClockLoader";
import "./style.css";

const Loader = () => {
  const isLoading = useSelector((state) => state.isLoading);
  return isLoading ? (
    <div className="backdrop">
      <ClockLoader size={50} color={"#FFF"} loading={isLoading} />
    </div>
  ) : null;
};

export default Loader;
