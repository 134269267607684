import React, { useEffect, useState } from "react";
import Navigation from "../Navigation";
import Footer from "../../Footer";
import Sidebar from "../Sidebar/index";
import Logo from "../../../images/logo.png";
import axios from "axios";
import {
  actionScreenUpdate,
  hideLoader,
  showLoader,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import "../../../css/style.css";
import "../../../css/bootstrap.min.css";

function EmployeeDocuments() {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_FILE_PATH = process.env.REACT_APP_FILE_PATH;
  const [state, setState] = useState({});
  const userId = localStorage.getItem("id");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionScreenUpdate("EMPLOYEE_DOCUMENTS"));
    dispatch(showLoader());
    async function fetchData() {
      axios
        .get(`${REACT_APP_API_URL}employees/get_employee_by_id?id=${userId}`)
        .then(function (response) {
          dispatch(hideLoader());
          const data = response.data.list[0];
          setState(data);
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(err.message);
        });
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div
        className="d-flex h-dashboard bg client"
        id="wrapper"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Sidebar />
        <div id="page-content-wrapper" className="height-overflow">
          <Navigation state={state} name={state.first_name} />
          <div className="container-fluid">
            <div
              className="container Profile "
              style={{ padding: "0px", margin: "0px" }}
            >
              {state.nda && (
                <div
                  className="card border-0 m-5 bg-gray"
                  style={{ backgroundColor: "#004169" }}
                >
                  <div className="card-body">
                    <div className="d-flex align-items-center">
                      <div className="flex-shrink-0">
                        <i className="fal fa-file-pdf"></i>
                      </div>
                      <div className="flex-grow-1 ms-3 w-50 text-white">
                        NDA
                      </div>
                      <div className="flex-shrink-0">
                        <a
                          href={
                            REACT_APP_API_URL + REACT_APP_FILE_PATH + state.nda
                          }
                          target="_blank"
                          className="btn btn-link text-white"
                        >
                          View
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div
                className="card border-0 m-5"
                style={{ backgroundColor: "#004169" }}
              >
                <div className="card-body">
                  <div className="d-flex align-items-center">
                    <div className="flex-shrink-0">
                      <i className="fal fa-file-pdf"></i>
                    </div>
                    <div className="flex-grow-1 ms-3 w-50 text-white">
                      Contract
                    </div>
                    <div className="flex-shrink-0">
                      <a
                        href={
                          REACT_APP_API_URL +
                          REACT_APP_FILE_PATH +
                          state.contract
                        }
                        target="_blank"
                        className="btn btn-link text-white"
                      >
                        View
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default EmployeeDocuments;
