import React, { useEffect, useState } from "react";
import Navigation from "../Navigation";
import Footer from "../../Footer";
import Sidebar from "../Sidebar/index";
import Logo from "../../../images/logo.png";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  hideLoader,
  showLoader,
  actionScreenUpdate,
} from "../../../redux/actions";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
  MDBIcon,
} from "mdb-react-ui-kit";
import { useDispatch, useSelector } from "react-redux";
import user from "../../../images/users.png";
import "../../../css/style.css";
import "../../../css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";

function EmployeeProfile() {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_FILE_PATH = process.env.REACT_APP_FILE_PATH;
  const [state, setState] = useState({});
  const userId = localStorage.getItem("id");
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => {
    setShow(false);
    localStorage.setItem("profile_updated", false);
  };

  const handleYes = () => {
    navigate("/EditEmployeeProfile");
  };

  useEffect(() => {
    setTimeout(() => {
      const profile = localStorage.getItem("profile_updated");
      if ((profile === "false" || !profile) && !state.profile_picture) {
        state.first_name && setShow(true);
      }
    }, 1000);
  }, [state]);

  useEffect(() => {
    dispatch(actionScreenUpdate("EMPLOYEE_PROFILE"));
    dispatch(showLoader());
    async function fetchData() {
      await axios
        .get(`${REACT_APP_API_URL}employees/get_employee_by_id?id=${userId}`)
        .then(function (response) {
          dispatch(hideLoader());
          const data = response.data.list[0];
          if (data.profile_picture)
            localStorage.setItem("profile_updated", true);
          setState(data);
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(err.message);
        });
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div
        className="d-flex h-dashboard bg client"
        id="wrapper"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Sidebar />
        <div id="page-content-wrapper" className="height-overflow">
          <Navigation name={state.first_name} state={state} />
          <MDBContainer className="py-0">
            <MDBRow className="justify-content-center align-items-center h-100">
              <MDBCol lg="9" className="mb-4 mb-lg-0">
                <MDBCard className="h-100" style={{ borderRadius: ".5rem" }}>
                  <MDBRow className="g-0">
                    <MDBCol
                      md="4"
                      className="gradient-custom text-center text-white"
                      style={{
                        borderTopLeftRadius: ".5rem",
                        borderBottomLeftRadius: ".5rem",
                      }}
                    >
                      <MDBCardImage
                        src={
                          state.profile_picture
                            ? `${
                                REACT_APP_API_URL +
                                REACT_APP_FILE_PATH +
                                state.profile_picture
                              }`
                            : user
                        }
                        alt="User"
                        className="my-5 man"
                        style={{
                          width: "170px",
                          height: "170px",
                          border: "3px",
                        }}
                        fluid
                      />
                      <MDBTypography tag="h5" className="mt-0">
                        {state.first_name + " " + state.last_name}
                      </MDBTypography>
                      <MDBCardText>{state.department}</MDBCardText>
                      <button
                        className="btn btn-info custum-btn-hover mb-4"
                        style={{ backgroundColor: "#004169", border: "none" }}
                      >
                        <Link
                          className="text-decoration-none text-white"
                          to="/EditEmployeeProfile"
                        >
                          Update Profile
                        </Link>
                      </button>
                    </MDBCol>
                    <MDBCol md="8">
                      <MDBCardBody className="p-4">
                        <MDBTypography tag="h6">Information</MDBTypography>
                        <hr className="mt-0 mb-4" />
                        <MDBRow className="pt-1">
                          <MDBCol size="12" className="mb-3">
                            <MDBTypography tag="h6">Email</MDBTypography>
                            <MDBCardText className="text-muted">
                              {state.email}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="pt-1">
                          <MDBCol size="6" className="mb-3">
                            <MDBTypography tag="h6">Phone</MDBTypography>
                            <MDBCardText className="text-muted">
                              {state.phone_number}
                            </MDBCardText>
                          </MDBCol>
                          <MDBCol size="6" className="mb-3">
                            <MDBTypography tag="h6">status</MDBTypography>
                            <MDBCardText className="text-muted">
                              {state.status}
                            </MDBCardText>
                          </MDBCol>
                        </MDBRow>
                        <MDBRow className="pt-1">
                          {state.street_address && (
                            <MDBCol size="12" className="mb-3">
                              <MDBTypography tag="h6">Address</MDBTypography>
                              <MDBCardText className="text-muted">
                                {state.street_address}
                              </MDBCardText>
                            </MDBCol>
                          )}
                          <MDBCol size="6" className="mb-3">
                            <MDBTypography tag="h6">wage</MDBTypography>
                            <MDBCardText className="text-muted">
                              &pound;{state.wage}
                            </MDBCardText>
                          </MDBCol>
                          {state.city && (
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">City</MDBTypography>
                              <MDBCardText className="text-muted">
                                {state.city}
                              </MDBCardText>
                            </MDBCol>
                          )}
                        </MDBRow>
                        <MDBRow className="pt-1">
                          {state.state && (
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">State</MDBTypography>
                              <MDBCardText className="text-muted">
                                {state.state}
                              </MDBCardText>
                            </MDBCol>
                          )}
                          {state.zip_code && (
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">Zip Code</MDBTypography>
                              <MDBCardText className="text-muted">
                                {state.zip_code}
                              </MDBCardText>
                            </MDBCol>
                          )}
                          {state.country && (
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">Country</MDBTypography>
                              <MDBCardText className="text-muted">
                                {state.country}
                              </MDBCardText>
                            </MDBCol>
                          )}
                        </MDBRow>
                      </MDBCardBody>
                    </MDBCol>
                  </MDBRow>
                </MDBCard>
              </MDBCol>
            </MDBRow>
          </MDBContainer>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            Please sign the contract and update Your Personal Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          It is mandate to maintain the compliance of business processes.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Later
          </Button>
          <Button variant="primary" onClick={handleYes}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
      <Footer />
    </React.Fragment>
  );
}

export default EmployeeProfile;
