import { useDispatch, useSelector } from "react-redux";
import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { hideLoader, showLoader } from '../../redux/actions'
import { useAPI } from "../../Api/context";


const EditEmployee = (props) => {
    const { getDepartments, departmentList, editEmployee } = useAPI();
    const handleClose = props.popup
    const refersh = props.refresh
    var employeeObj = props.employeeList[props.pos];

    const [firstname, setFirstName] = useState(employeeObj['first_name'])
    const [lastname, setLastName] = useState(employeeObj['last_name'])
    const [status, setStatus] = useState(employeeObj.status)
    const [email, setEmail] = useState(employeeObj.email)
    const [department, setDepartment] = useState(employeeObj.department)
    const [error, setError] = useState({ firstnameError: false, lastnameError: false, emailError: false, designationError: false, departmentError: false });


    const handleChangeFirstName = (e) => { setFirstName(e.target.value.trim()) }

    const handleChangeLastName = (e) => { setLastName(e.target.value.trim()) }

    const handleChangeEmail = (e) => { setEmail(e.target.value.trim()) }

    const handleChangeDepartment = (e) => { setDepartment(e.target.value.trim()) }

    const handleChangeStatus = (e) => { setStatus(e.target.value.trim()) }

    function validateEmail(email) {
        const re =
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    useEffect(() => {
        getDepartments()
    }, [])

    const clearData = () => {
        setFirstName('')
        setLastName('')
        setEmail('')
        setDepartment('')
    }

    const EditEmployeeDetails = () => {
        setError({
            firstnameError: false,
            lastnameError: false,
            emailError: false,
            statusError: false,
            departmentError: false
        })
        if (firstname === '') {
            setError((prevState) => ({
                ...prevState,
                firstnameError: true,
            }));
            return;

        } else if (lastname === '') {
            setError((prevState) => ({
                ...prevState,
                lastnameError: true,
            }));
            return;

        } else if (email === '') {
            setError((prevState) => ({
                ...prevState,
                emailError: true,
            }));
            return;

        } else if (!validateEmail(email)) {
            setError((prevState) => ({
                ...prevState,
                emailError: true,
            }));
            return;
        }
        else if (department === 'Select Department') {
            setError((prevState) => ({
                ...prevState,
                departmentError: true,
            }));
            return;

        } else if (status === 'Select Status') {
            setError((prevState) => ({
                ...prevState,
                statusError: true,
            }));
            return;

        }
        else {
            const data = {
                first_name: firstname,
                last_name: lastname,
                email: email,
                status: status,
                department: department,
                id: employeeObj.id
            }
            handleClose()
            const response = editEmployee(data);
            response.then((responseData) => {
                if (responseData === true) {
                    clearData()
                    refersh()
                } else {
                    alert(responseData)
                }
            }
            )
        }
    }

    return (
        <div id="page-content-wrapper">
            <div className="container-fluid">
                <div className="col-md-12">
                    <form className="row">

                        <div className="col-md-6">
                            <input value={firstname} onChange={handleChangeFirstName} type="text" className="form-control" placeholder="First name" aria-label="First name" />
                            {error.firstnameError ?
                                <div className="add-employee-error">
                                    Please enter the first name.
                                </div> : null}
                        </div>
                        <div className="col-md-6">
                            <input value={lastname} onChange={handleChangeLastName} type="text" className="form-control" placeholder="Last name" aria-label="Last name" />
                            {error.lastnameError ?
                                <div className="add-employee-error">
                                    Please enter the last name.
                                </div> : null}
                        </div>
                        <div className="col-12">
                            <input value={email} onChange={handleChangeEmail} type="email" className="form-control" placeholder="Email ID" id="inputEmail3" />
                            {error.emailError ?
                                <div className="add-employee-error">
                                    Please enter a valid email.
                                </div> : null}
                        </div>

                        <div className="col-md-6">
                            <label className="visually-hidden" htmlFor="autoSizingSelect">Status</label>
                            <select onChange={handleChangeStatus} className="form-select" id="autoSizingSelect">
                                <option value="Select Status" defaultValue>{status}</option>
                                <option value="Active">Active</option>
                                <option value="Terminated">Terminated</option>
                            </select>
                            {error.statusError ?
                                <div className="add-employee-error">
                                    Please select a status
                                </div> : null}
                        </div>

                        <div className="col-md-6">
                            <label className="visually-hidden" htmlFor="autoSizingSelect">Department</label>
                            <select onChange={handleChangeDepartment} className="form-select" id="autoSizingSelect">

                                <option defaultValue>{employeeObj.department}</option>
                                {departmentList.map((item, index) => (
                                    <option key={index} value={item.name}>
                                        {item.name}
                                    </option>
                                ))}
                            </select>

                            {error.departmentError ?
                                <div className="add-employee-error">
                                    Please select a department.
                                </div> : null}
                        </div>
                        <div className="col-md-12">
                            <button onClick={EditEmployeeDetails} type="button" className="btn btn-primary add" data-bs-toggle="modal" data-bs-target="#exampleModal">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    )
}

export default EditEmployee;