/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import Navigation from "../Navigation";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import {
  hideLoader,
  showLoader,
  actionScreenUpdate,
} from "../../redux/actions";
import { useDispatch } from "react-redux";
import {
  MDBCol,
  MDBRow,
  MDBCardText,
  MDBCardBody,
  MDBTypography,
} from "mdb-react-ui-kit";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Card from "react-bootstrap/Card";
import { JOB_COMPONENT } from "./utils";
import Model from "./Model";
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import "./style.css";
import NewJob from "./NewJob";
import { useAPI } from "../../Api/context";
import JobDetails from "./JobDetails";

function JobScheduler() {
  const { getJob, showCalender, jobs, deleteJob } = useAPI();
  const [component, setComponent] = useState(JOB_COMPONENT.initial);
  const [show, setShow] = useState(false);
  const [data, setData] = useState({});
  const [selectDate, setSelectDate] = useState("");
  const [jobDetails, setJobDetails] = useState({});
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(showLoader());
    getJob();
    dispatch(hideLoader());
    dispatch(actionScreenUpdate("JobScheduler"));
  }, []);

  const setComponentFunction = (data) => {
    setComponent(data);
    setShow(false);
  };

  const handleDateSelect = (selectInfo) => {
    const date = new Date(selectInfo.start).toDateString();
    setSelectDate(date);
    setShow(true);
    setData({
      header: `Add to ${date}`,
      data: () => {
        return (
          <>
            <div className="list-group">
              <a
                className="list-group-item list-group-item-action m-2"
                onClick={() => setComponentFunction(JOB_COMPONENT.newJob)}
              >
                <i className="bi bi-hammer m-1"></i>New Job
              </a>
            </div>
          </>
        );
      },
    });
  };

  const renderEventContent = (eventInfo) => {
    // console.log(eventInfo);
    const status = eventInfo.event._def.extendedProps.status || "";
    return (
      <div
        className={`task-item d-flex gap-2 justify-content-center m-auto
      ${status === "complete" && "bg-success text-white"}
      ${status === "pending" && "bg-danger text-white"}
      ${status === "ongoing" && "bg-warning text-black"}`}
      >
        <b>
          {eventInfo.event.start.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          })}
        </b>
        <span>{eventInfo.event.title}</span>
      </div>
    );
  };

  const handleEventClick = (clickInfo) => {
    const { title, extendedProps, publicId } = clickInfo.event._def;
    let {
      discription,
      jobFor,
      jobType,
      jobNumber,
      city,
      jobBy,
      landMark,
      postalCode,
      street,
      status,
      time,
      maxhours,
      healthandsafetycheck
    } = extendedProps;
    // const startDate = new Date(clickInfo.event.start).toLocaleString();
    const startDate = clickInfo.event.start.toLocaleString();
    jobFor = jobFor.split(",");
    let endDate;
    if (clickInfo.event.end)
      endDate = new Date(clickInfo.event.end).toLocaleString();
    setJobDetails({
      title,
      publicId,
      discription,
      jobFor,
      jobType,
      jobNumber,
      city,
      jobBy,
      landMark,
      postalCode,
      street,
      startDate,
      endDate,
      status,
      time,
      maxhours,
      healthandsafetycheck
    });
    setShow(true);
    setData({
      header: `Job On ${startDate}`,
      data: () => {
        return (
          <>
            <Card>
              <MDBCardBody className="p-4">
                <MDBTypography tag="h6">Job Information</MDBTypography>
                <hr className="mt-0 mb-4" />
                <MDBRow className="pt-1">
                  <MDBCol size="6" className="mb-3">
                    <MDBTypography tag="h6">Job Number</MDBTypography>
                    <MDBCardText className="text-muted">
                      {jobNumber}
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol size="6" className="mb-3">
                    <MDBTypography tag="h6">Title</MDBTypography>
                    <MDBCardText className="text-muted">{title}</MDBCardText>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="pt-1">
                  <MDBCol size="6" className="mb-3">
                    <MDBTypography tag="h6">Start</MDBTypography>
                    <MDBCardText className="text-muted">
                      {startDate}
                    </MDBCardText>
                  </MDBCol>
                  <MDBCol size="6" className="mb-3">
                    <MDBTypography tag="h6">End</MDBTypography>
                    <MDBCardText className="text-muted">
                      {endDate ? endDate : "Not Included"}
                    </MDBCardText>
                  </MDBCol>
                </MDBRow>
                <MDBRow className="pt-1">
                  <MDBCol size="6" className="mb-3">
                    <MDBTypography tag="h6">Job Type</MDBTypography>
                    <MDBCardText className="text-muted">{jobType}</MDBCardText>
                  </MDBCol>
                  <MDBCol size="6" className="mt-4">
                    <button
                      type="button"
                      className="btn btn-outline-info"
                      onClick={() => {
                        setComponent(JOB_COMPONENT.jobDetails);
                        setShow(false);
                      }}
                    >
                      Read More
                    </button>
                  </MDBCol>
                </MDBRow>
              </MDBCardBody>
            </Card>
          </>
        );
      },
      deleteJob: (deleteRecursive) => {
        const response = deleteJob(publicId,deleteRecursive);
        response.then((responseData) => {
          if (responseData === true) {
            if(deleteRecursive){
             window.location.reload(true);
            }else{
              setShow(false);
              clickInfo.event.remove();
              getJob();
            }
          } else {
            setShow(false);
            alert(responseData);
          }
        });
      },
    });
  };

  const handleEvents = (events) => {};

  return (
    <React.Fragment>
      <div
        className="d-flex h-dashboard bg"
        id="wrapper"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Sidebar />
        <div id="page-content-wrapper" className="height-overflow">
          <Navigation />
          {component.calendar && (
            <>
            <div className="container-fluid mt-0 ">
              {(jobs.length > 0 || showCalender) && (
                <FullCalendar
                  plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  headerToolbar={{
                    left: "prev,next today",
                    center: "title",
                    right: "dayGridMonth,timeGridWeek,timeGridDay",
                  }}
                  initialView="dayGridMonth"
                  editable={true}
                  selectable={true}
                  selectMirror={true}
                  dayMaxEvents={true}
                  weekends={true}
                  initialEvents={jobs}
                  select={handleDateSelect}
                  eventContent={renderEventContent}
                  eventClick={handleEventClick}
                  eventsSet={handleEvents} // called after events are initialized/added/changed/removed
                  themeSystem={"bootstrap5"}
                />
              )}
            </div>
            <div className="color-show container-fluid">
            <div className="d-flex gap-2 fs-5 align-items-center"><div className="color bg-success"></div>Status: Complete</div>
            <div className="d-flex gap-2 fs-5 align-items-center"><div className="color bg-warning"></div>Status: Ongoing</div>
            <div className="d-flex gap-2 fs-5 align-items-center"><div className="color bg-danger"></div>Status: Pending</div>
              </div>
            </>
          )}
          {component.jobDetails && (
            <div className="container-fluid mt-0">
              <JobDetails data={{ setComponent, getJob, jobDetails }} />
            </div>
          )}
          {component.newJob && (
            <div className="container-fluid mt-0">
              <NewJob data={{ setComponent, getJob, selectDate }} />
            </div>
          )}
        </div>
      </div>
      <Footer />
      <Model data={{ ...data, show, setShow, jobDetails }} />
    </React.Fragment>
  );
}

export default JobScheduler;
