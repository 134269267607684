import React from 'react'

function Footer() {
  return (
    // <footer className="footer fixed-bottom">
    //   <nav className="navbar">
    //     <div className="container-fluid">
    //       <p className="copyright-text m-0">Copyright© 2022 Ferguson Cleaning Solutions. All Rights Reserved.</p>
    //       <ul className="nav">
    //         <li className="nav-item">
    //           <a className="nav-link active" href="#">Terms &amp; Conditions</a>
    //         </li>
    //         <li className="nav-item">
    //           <a className="nav-link" href="#">Privacy Policy</a>
    //         </li>
    //       </ul>
    //     </div>
    //   </nav>
    // </footer>
    <></>
  )
}

export default Footer