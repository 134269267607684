import React, { useEffect, useRef, useState } from "react";
import Navigation from "../Navigation";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import {
  actionScreenUpdate,
  hideLoader,
  showLoader,
} from "../../redux/actions";
import { useDispatch } from "react-redux";
import { useAPI } from "../../Api/context";
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import AlertModal from "../AlertModel";
import axios from "axios";
import GoogleMap from "../GoogleMap/UseMap";
import LocationSearchInput from "../Adress";

function AddClients() {
  const initialerror = {
    companyName: false,
    email: false,
    phone: false,
    emailType: false,
    phoneType: false,
    address: false,
    referredby: false,
    quote: false,
    appointment: false,
    job: false,
    invoice: false,
    healthandsafetycheck: false,
    specificationSheet: false,
  };
  const initialState = {
    companyName: "",
    email: "",
    phone: "",
    emailType: "main",
    phoneType: "main",
    referredby: "",
    quote: true,
    appointment: false,
    job: false,
    invoice: false,
    healthandsafetycheck: "",
    specificationSheet: "",
  };
  const dispatch = useDispatch();
  const { addClient } = useAPI();
  const [state, setState] = useState(initialState);
  const [error, setError] = useState(initialerror);
  const [address, setAddress] = useState("");
  const [data, setData] = useState({});
  const [show, setShow] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const containerRef = useRef(null);

  const changeFunction = (e) => {
    if (e.target.name === "healthandsafetycheck" || e.target.name === "specificationSheet") {
      if(e?.target?.files[0]?.name){
        setState((pre) => ({ ...pre, [e.target.name]: e.target.files[0].name }));
      }else{
        setState((pre) => ({ ...pre, [e.target.name]: "" }));
      }
    } else {
      if (e.target.type === "checkbox") {
        setState((data) => ({ ...data, [e.target.name]: e.target.checked }));
      } else {
        setState((data) => ({ ...data, [e.target.name]: e.target.value }));
      }
    }
  };

  const addClientFunction = () => {
    setError(initialerror);
    const { companyName, email, phone, healthandsafetycheck,specificationSheet } = state;
    if (companyName === "") {
      setError((data) => ({ ...data, companyName: true }));
      containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    } else if (phone === "") {
      setError((data) => ({ ...data, phone: true }));
      containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    } else if (email === "") {
      setError((data) => ({ ...data, email: true }));
      containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    } else if (address === "") {
      setError((data) => ({ ...data, address: true }));
      containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
    } else if (healthandsafetycheck === "") {
      setError((data) => ({ ...data, healthandsafetycheck: true }));
    } else if (specificationSheet === "") {
      setError((data) => ({ ...data, specificationSheet: true }));
    }  else {
      setError(initialerror);
      dispatch(showLoader());
      const formData = new FormData();
      const healthandsafetycheck = document.getElementById("healthandsafetycheck").files[0];
      const specificationSheet = document.getElementById("specificationSheet").files[0];
      formData.append("healthandsafetycheckfile", healthandsafetycheck);
      formData.append("specificationSheet", specificationSheet);
      formData.append("address", address);
      for (const property in state) {
        formData.append(property, state[property]);
      }
      const data = addClient(formData);
      data
        .then((data) => {
          if (data.data.status === 1) {
            setData((data) => ({
              ...data,
              header: "New Client",
              body: "Client Added Succesfully",
              navigate: "/CLIENTS",
            }));
            setShow(true);
          } else {
            setData((dataa) => ({
              ...dataa,
              header: "Failed!",
              body: data.data.message,
            }));
            setShow(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
      dispatch(hideLoader());
    }
  };

  useEffect(() => {
    dispatch(actionScreenUpdate("ADD_CLIENT"));
  }, []);
  return (
    <React.Fragment>
      <div className="d-flex h-dashboard bg client" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <Navigation />
          <div className="container-fluid" ref={containerRef}>
            <form className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="page-heading mb-4">CLIENT DETAILS </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <input
                      onChange={changeFunction}
                      name="companyName"
                      type="text"
                      className="form-control"
                      placeholder="Company Name"
                      id="inputEmail3"
                    />
                    {error.companyName ? (
                      <div
                        className="alert alert-danger alert-dismissible fade show h6"
                        role="alert"
                      >
                        <strong>Error!</strong> Please add the Company Name.
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError(initialerror)}
                        ></button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mix-input">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend mb-0">
                        <label
                          className="visually-hidden"
                          htmlFor="autoSizingSelect"
                        >
                          Main
                        </label>
                        <select
                          onChange={changeFunction}
                          name="phoneType"
                          className="form-select"
                          id="autoSizingSelect"
                        >
                          <option value="main" defaultValue>
                            Main
                          </option>
                          <option value="office">Office</option>
                          <option value="home">Home</option>
                        </select>
                      </div>
                      <input
                        onChange={changeFunction}
                        name="phone"
                        type="number"
                        className="form-control phone"
                        placeholder="Phone Number"
                      />
                    </div>
                    {error.phone ? (
                      <div
                        className="alert alert-danger alert-dismissible fade show h6"
                        role="alert"
                      >
                        <strong>Error!</strong> Please add the Phone Number.
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError(initialerror)}
                        ></button>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mix-input">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend mb-0">
                        <label
                          className="visually-hidden"
                          htmlFor="autoSizingSelect"
                        >
                          Main
                        </label>
                        <select
                          onChange={changeFunction}
                          name="emailType"
                          className="form-select"
                          id="autoSizingSelect"
                        >
                          <option value="main" defaultValue>
                            Main
                          </option>
                          <option value="office">Office</option>
                          <option value="home">Home</option>
                        </select>
                      </div>
                      <input
                        onChange={changeFunction}
                        name="email"
                        type="text"
                        className="form-control phone"
                        placeholder="Email Address"
                      />
                    </div>
                    {error.email ? (
                      <div
                        className="alert alert-danger alert-dismissible fade show h6"
                        role="alert"
                      >
                        <strong>Error!</strong> Please add the Email.
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError(initialerror)}
                        ></button>
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="col-md-12 mb-4 mt-2"> <h6>+ ADD EMAIL ADDRESS</h6></div> */}
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h2 className="page-heading mb-4">
                      ADDITIONAL CLIENT DETAILS
                    </h2>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <input
                      name="referredby"
                      onChange={changeFunction}
                      type="text"
                      className="form-control"
                      placeholder="Referred By"
                      aria-label="First name"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h2 className="page-heading mb-4">Health & Safety Check</h2>
                  </div>
                </div>

                <div className="row">
                  <div className="input-group-custom col-md-12">
                    <input
                      type="file"
                      className="form-control-custom"
                      onChange={changeFunction}
                      id="healthandsafetycheck"
                      name="healthandsafetycheck"
                    />
                    <label
                      className="input-group-text-custom bg-secondary-custom"
                      htmlFor="healthandsafetycheck"
                    >
                      Upload Health & Safety Check
                    </label>
                  </div>
                  {error.healthandsafetycheck ? (
                    <div className="add-employee-error">
                      Please add Health & Safety Check File.
                    </div>
                  ) : null}
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h2 className="page-heading mb-4 mt-4">
                      Specification Sheet
                    </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="input-group-custom col-md-12">
                    <input
                      type="file"
                      className="form-control-custom"
                      onChange={changeFunction}
                      id="specificationSheet"
                      name="specificationSheet"
                    />
                    <label
                      className="input-group-text-custom bg-secondary-custom"
                      htmlFor="specificationSheet"
                    >
                      Upload Specification Sheet
                    </label>
                  </div>
                  {error.specificationSheet ? (
                    <div className="add-employee-error">
                      Please add Specification Sheet File.
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="page-heading mb-4">PROPERTY DETAILS</h2>
                  </div>
                </div>

                <div className="row">
                  <div className="content d-flex gap-1 align-items-center mb-0">
                    <h5>Address</h5>
                  </div>
                  <LocationSearchInput setAddress={setAddress} />
                  {error.address ? (
                    <div
                      className="alert alert-danger alert-dismissible fade show h6"
                      role="alert"
                    >
                      <strong>Error!</strong> Please add the Address.
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => setError(initialerror)}
                      ></button>
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <h2 className="page-heading mt-4">Automated notifications</h2>

                  <div className="row">
                    <div className="col-sm-12 content mt-4">
                      <h6>Quote follow-up</h6>
                      <div className="form-check form-switch form-switch-lg">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Follow up on an outstanding quote.
                        </label>
                        <input
                          name="quote"
                          onChange={changeFunction}
                          checked={state.quote}
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 content">
                      <h6>Appointment reminders</h6>
                      <div className="form-check form-switch form-switch-lg">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Remind your client of an upcoming assessment or visit.
                        </label>
                        <input
                          name="appointment"
                          onChange={changeFunction}
                          checked={state.appointment}
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 content">
                      <h6>Job follow-up</h6>
                      <div className="form-check form-switch form-switch-lg">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Follow up when you close a job.
                        </label>
                        <input
                          name="job"
                          onChange={changeFunction}
                          checked={state.job}
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 content">
                      <h6>Invoice follow-up</h6>
                      <div className="form-check form-switch form-switch-lg">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Follow up when you close a job.
                        </label>
                        <input
                          name="invoice"
                          onChange={changeFunction}
                          checked={state.invoice}
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <button
                  onClick={addClientFunction}
                  type="button"
                  className="btn btn-primary add mt-4 mb-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Create Client
                </button>
                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="exampleModalLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body mt-4  text-center">
                        <h2>Client Added Successfully.</h2>
                        <p>
                          Login details has been sent to registered email ID.{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
      <AlertModal data={{ ...data, show, setShow }} />
      <GoogleMap data={{ showMap, setShowMap, address, setAddress }} />
    </React.Fragment>
  );
}

export default AddClients;
