import React, { useEffect, useState } from "react";
import Navigation from "../Navigation";
import Footer from "../../Footer";
import Sidebar from "../Sidebar/index";
import Logo from "../../../images/logo.png";
import axios from "axios";
import {
  actionScreenUpdate,
  hideLoader,
  showLoader,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import "../../../css/style.css";
import "../../../css/bootstrap.min.css";
import { Link } from "react-router-dom";

function EmployeePayRoll() {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const [state, setState] = useState({});
  const userId = localStorage.getItem("id");
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actionScreenUpdate("EMPLOYEE_PAY_ROLL"));
    dispatch(showLoader());
    async function fetchData() {
      axios
        .get(`${REACT_APP_API_URL}employees/get_employee_by_id?id=${userId}`)
        .then(function (response) {
          dispatch(hideLoader());
          const data = response.data.list[0];
          setState(data);
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(err.message);
        });
    }
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div
        className="d-flex h-dashboard bg client"
        id="wrapper"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Sidebar />
        <div id="page-content-wrapper" className="height-overflow">
          <Navigation name={state.first_name} state={state} />
          <div className="container-fluid">
            <div
              className="container Profile "
              style={{ padding: "0px", margin: "0px" }}
            ></div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default EmployeePayRoll;
