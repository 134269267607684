import React, { useEffect, useRef, useState } from 'react'
import { useAPI } from "../../Api/context";
import AlertModal from '../AlertModel';
import GoogleMap from '../GoogleMap/UseMap';
import { hideLoader, showLoader } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import LocationSearchInput from '../Adress';


const EditClient = (props) => {
    const { editClient } = useAPI();
    const clientData = props.data;
    const initialerror = {
        companyName: false,
        email: false,
        phone: false,
        emailType: false,
        phoneType: false,
        address: false,
        referredby: false,
        quote: false,
        appointment: false,
        job: false,
        invoice: false,
        healthandsafetycheck: false,
        specificationSheet: false,
      };
    const initialState = {
        companyName: clientData?.companyName || "",
        email: clientData?.email || "",
        phone: clientData?.phone || "",
        emailType: clientData?.emailType || "main",
        phoneType: clientData?.phoneType ||"main",
        referredby: clientData?.referredby ||"",
        quote: clientData?.quote === "true" ? true : false,
        appointment: clientData?.appointment ==="true"? true:false,
        job:clientData?.job ==="true"? true:false,
        invoice:clientData?.invoice ==="true"? true:false,
        healthandsafetycheck:clientData?.healthandsafetycheck || "",
        specificationSheet:clientData?.specificationSheet || "",
      };
    const handleClose = props.popup

      const [state, setState] = useState(initialState);
      const [error, setError] = useState(initialerror);
      const [address, setAddress] = useState("");
      const [data, setData] = useState({});
      const [show, setShow] = useState(false);
      const [showMap, setShowMap] = useState(false);
      const containerRef = useRef(null);
      const dispatch = useDispatch();
      const clearData = () => {
        setState(initialState);
        setError(initialerror);
    }
    
      const changeFunction = (e) => {
        if (e.target.name === "healthandsafetycheck" || e.target.name==="specificationSheet") {
          if(e?.target?.files[0]?.name){
            setState((pre) => ({ ...pre, [e.target.name]: e.target.files[0].name }));
          }else{
            setState((pre) => ({ ...pre, [e.target.name]: clientData[e.target.name] }));
          }
        } else {
          if (e.target.type === "checkbox") {
            setState((data) => ({ ...data, [e.target.name]: e.target.checked }));
          } else {
            setState((data) => ({ ...data, [e.target.name]: e.target.value }));
          }
        }
      };
      useEffect(()=>{
        setAddress(clientData.address)
      },[])
    
      const addClientFunction = () => {
        setError(initialerror);
        const { companyName, email, phone } = state;
        if (companyName === "") {
          setError((data) => ({ ...data, companyName: true }));
          containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
        } else if (phone === "") {
          setError((data) => ({ ...data, phone: true }));
          containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
        } else if (email === "") {
          setError((data) => ({ ...data, email: true }));
          containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
        } else if (address === "") {
          setError((data) => ({ ...data, address: true }));
          containerRef.current.scrollIntoView({ behavior: "smooth", top: 0 });
        } else {
          setError(initialerror);
          dispatch(showLoader());
          const formData = new FormData();
          const healthandsafetycheck = document.getElementById("healthandsafetycheck").files[0];
          const specificationSheet = document.getElementById("specificationSheet").files[0];
          if(healthandsafetycheck) formData.append("healthandsafetycheckfile", healthandsafetycheck);
          // else formData.append("healthandsafetycheckfile", clientData?.healthandsafetycheck);
          if(specificationSheet) formData.append("specificationSheet", specificationSheet);
          // else formData.append("healthandsafetycheck", clientData?.specificationSheet);
          formData.append("address", address);
          for (const property in state) {
            formData.append(property, state[property]);
          }
          const data = editClient(formData,clientData.id);
          data.then((data) => {
              if (data.data.status === 1) {
                setData((data) => ({
                  ...data,
                  header: "Client",
                  body: "Client Updated Succesfully",
                  navigate: "/CLIENTS",
                  handleClose:handleClose
                }));
                setShow(true);
                clearData();
              } else {
                setData((dataa) => ({
                  ...dataa,
                  header: "Failed!",
                  body: data.data.message,
                }));
                setShow(true);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          dispatch(hideLoader());
        }
      };
    console.log(state,clientData)
    return (
        <div id="page-content-wrapper">
          <div className="container-fluid" ref={containerRef}>
            <form className="row">
              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="page-heading mb-4">CLIENT DETAILS </h2>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <input
                      onChange={changeFunction}
                      value={state.companyName}
                      name="companyName"
                      type="text"
                      className="form-control"
                      placeholder="Company Name"
                      id="inputEmail3"
                    />
                    {error.companyName ? (
                      <div
                        className="alert alert-danger alert-dismissible fade show h6"
                        role="alert"
                      >
                        <strong>Error!</strong> Please add the Company Name.
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError(initialerror)}
                        ></button>
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 mix-input">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend mb-0">
                        <label
                          className="visually-hidden"
                          htmlFor="autoSizingSelect"
                        >
                          Main
                        </label>
                        <select
                          onChange={changeFunction}
                          value={state.phoneType}
                          name="phoneType"
                          className="form-select"
                          id="autoSizingSelect"
                        >
                          <option value="main" defaultValue>Main</option>
                          <option value="office">Office</option>
                          <option value="home">Home</option>
                        </select>
                      </div>
                      <input
                        onChange={changeFunction}
                        value={state.phone}
                        name="phone"
                        type="number"
                        className="form-control phone"
                        placeholder="Phone Number"
                      />
                    </div>
                    {error.phone ? (
                      <div
                        className="alert alert-danger alert-dismissible fade show h6"
                        role="alert"
                      >
                        <strong>Error!</strong> Please add the Phone Number.
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError(initialerror)}
                        ></button>
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12 mix-input">
                    <div className="input-group mb-2">
                      <div className="input-group-prepend mb-0">
                        <label
                          className="visually-hidden"
                          htmlFor="autoSizingSelect"
                        >
                          Main
                        </label>
                        <select
                          onChange={changeFunction}
                          value={state.emailType}
                          name="emailType"
                          className="form-select"
                          id="autoSizingSelect"
                        >
                          <option value="main" defaultValue>
                            Main
                          </option>
                          <option value="office">Office</option>
                          <option value="home">Home</option>
                        </select>
                      </div>
                      <input
                        onChange={changeFunction}
                        value={state.email}
                        name="email"
                        type="text"
                        className="form-control phone"
                        placeholder="Email Address"
                      />
                    </div>
                    {error.email ? (
                      <div
                        className="alert alert-danger alert-dismissible fade show h6"
                        role="alert"
                      >
                        <strong>Error!</strong> Please add the Email.
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setError(initialerror)}
                        ></button>
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="col-md-12 mb-4 mt-2"> <h6>+ ADD EMAIL ADDRESS</h6></div> */}
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h2 className="page-heading mb-4">
                      ADDITIONAL CLIENT DETAILS
                    </h2>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <input
                      name="referredby"
                      onChange={changeFunction}
                      value={state.referredby}
                      type="text"
                      className="form-control"
                      placeholder="Referred By"
                      aria-label="First name"
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <h2 className="page-heading mb-4">Health & Safety Check</h2>
                  </div>
                </div>

                <div className="row">
                  <div className="input-group-custom col-md-12">
                    <input
                      type="file"
                      className="form-control-custom"
                      onChange={changeFunction}
                      id="healthandsafetycheck"
                      name="healthandsafetycheck"
                    />
                    <label
                      className="input-group-text-custom bg-secondary-custom"
                      htmlFor="healthandsafetycheck"
                    >
                      Upload Health & Safety Check
                    </label>
                  </div>
                  {error.healthandsafetycheck ? (
                    <div className="add-employee-error">
                      Please add Health & Safety Check File.
                    </div>
                  ) : null}
                </div>


                <div className="row">
                  <div className="col-md-12">
                    <h2 className="page-heading mb-4 mt-4">Specification Sheet</h2>
                  </div>
                </div>

                <div className="row">
                  <div className="input-group-custom col-md-12">
                    <input
                      type="file"
                      className="form-control-custom"
                      onChange={changeFunction}
                      id="specificationSheet"
                      name="specificationSheet"
                    />
                    <label
                      className="input-group-text-custom bg-secondary-custom"
                      htmlFor="specificationSheet"
                    >
                      Upload Specification Sheet
                    </label>
                  </div>
                  {error.specificationSheet ? (
                    <div className="add-employee-error">
                      Please add Specification Sheet File.
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="page-heading mb-4">PROPERTY DETAILS</h2>
                  </div>
                </div>

                <div className="row">
                <div className="content d-flex gap-1 align-items-center mb-0"><h5>Address</h5></div>
                  <LocationSearchInput setAddress={setAddress}/>
                  {error.address ? (
                    <div
                      className="alert alert-danger alert-dismissible fade show h6"
                      role="alert"
                    >
                      <strong>Error!</strong> Please add the Address From the
                      map.
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="alert"
                        aria-label="Close"
                        onClick={() => setError(initialerror)}
                      ></button>
                    </div>
                  ) : null}
                </div>
                <div className="row">
                  <h2 className="page-heading mt-4">Automated notifications</h2>

                  <div className="row">
                    <div className="col-sm-12 content mt-4">
                      <h6>Quote follow-up</h6>
                      <div className="form-check form-switch form-switch-lg">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Follow up on an outstanding quote.
                        </label>
                        <input
                          name="quote"
                          onChange={changeFunction}
                          checked={state.quote}
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 content">
                      <h6>Appointment reminders</h6>
                      <div className="form-check form-switch form-switch-lg">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Remind your client of an upcoming assessment or visit.
                        </label>
                        <input
                          name="appointment"
                          onChange={changeFunction}
                          checked={state.appointment}
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 content">
                      <h6>Job follow-up</h6>
                      <div className="form-check form-switch form-switch-lg">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Follow up when you close a job.
                        </label>
                        <input
                          name="job"
                          onChange={changeFunction}
                          checked={state.job}
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>

                    <div className="col-sm-12 content">
                      <h6>Invoice follow-up</h6>
                      <div className="form-check form-switch form-switch-lg">
                        <label
                          className="form-check-label"
                          htmlFor="flexSwitchCheckDefault"
                        >
                          Follow up when you close a job.
                        </label>
                        <input
                          name="invoice"
                          onChange={changeFunction}
                          checked={state.invoice}
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckDefault"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-12 text-center">
                <button
                  onClick={addClientFunction}
                  type="button"
                  className="btn btn-primary add mt-4 mb-4"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                >
                  Update Client
                </button>
              </div>
            </form>
            <AlertModal data={{ ...data, show, setShow }} />
            <GoogleMap data={{ showMap, setShowMap, address, setAddress }} />
          </div>
        </div>


    )
}

export default EditClient;