import React, { useEffect, useState } from "react";
import Logo from "../../images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { actionScreenUpdate } from "../../redux/actions";
import { Link } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { FaBars } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import { BsPersonFillCheck } from "react-icons/bs";
import { BsPersonFillAdd } from "react-icons/bs";
import { BsPeopleFill } from "react-icons/bs";
import { IoBusiness } from "react-icons/io5";
import { FaTasks } from "react-icons/fa";
import { ImUserTie } from "react-icons/im";
import { IoCalendarNumber } from "react-icons/io5";
import { FaPlane } from "react-icons/fa";
import { GiTrophyCup } from "react-icons/gi";
import { CiViewBoard } from "react-icons/ci";
import { FaChartBar } from "react-icons/fa";
import { FaDollarSign } from "react-icons/fa";
import { SiGooglescholar } from "react-icons/si";
import { BiMessage } from "react-icons/bi";
import "./style.css";

const items = [
  {
    title: "Dashboard",
    Icon: RxDashboard,
    to: "/DASHBOARD",
    myScreen: "DASHBOARD",
  },
  // {
  //   title: "Add Employee",
  //   Icon: IoMdPersonAdd,
  //   to: "/ADD_EMPLOYEES",
  //   myScreen: "ADD_EMPLOYEES",
  // },
  // {
  //   title: "Employee List",
  //   Icon: BsPeopleFill,
  //   to: "/EmployeesList",
  //   myScreen: "EMPLOYEE",
  // },
  {
    title: "Departments",
    Icon: IoBusiness,
    to: "/DEPARTMENT",
    myScreen: "DEPARTMENT",
  },
  // {
  //   title: "Add Client",
  //   Icon: BsPersonFillAdd,
  //   to: "/ADD_CLIENT",
  //   myScreen: "ADD_CLIENT",
  // },
  // {
  //   title: "Client List",
  //   Icon: BsPersonFillCheck,
  //   to: "/CLIENTS",
  //   myScreen: "CLIENTS",
  // },
  // {
  //   title: "Job Scheduler",
  //   Icon: FaTasks,
  //   to: "/JobScheduler",
  //   myScreen: "JobScheduler",
  // },
  // {
  //   title: "Job Applicants",
  //   Icon: ImUserTie,
  //   to: "/JobApplicants",
  //   myScreen: "JobApplicants",
  // },
  {
    title: "Attendance",
    Icon: IoCalendarNumber,
    to: "/DASHBOARD",
    myScreen: "Attendance",
  },
  {
    title: "Holiday",
    Icon: FaPlane,
    to: "/DASHBOARD",
    myScreen: "Holiday",
  },
  {
    title: "Award",
    Icon: GiTrophyCup,
    to: "/DASHBOARD",
    myScreen: "Award",
  },
  {
    title: "Notice Board",
    Icon: CiViewBoard,
    to: "/DASHBOARD",
    myScreen: "Notice",
  },
  {
    title: "Expense",
    Icon: FaChartBar,
    to: "/DASHBOARD",
    myScreen: "Expense",
  },
  {
    title: "Payslips",
    Icon: FaDollarSign,
    to: "/DASHBOARD",
    myScreen: "Payslips",
  },
  {
    title: "Training",
    Icon: SiGooglescholar,
    to: "/DASHBOARD",
    myScreen: "Training",
  },
  {
    title: "Task",
    Icon: FaTasks,
    to: "/DASHBOARD",
    myScreen: "Tasks",
  },
  {
    title: "Support Tickets",
    Icon: BiMessage,
    to: "/DASHBOARD",
    myScreen: "Support",
  },
];

const Item = ({ screen, title, Icon, dispatch, isExpanded, to, myScreen }) => {
  return (
    <Link className="text-white text-decoration-none" to={to}>
      <div
        className={`nav-link dropdown-toggle d-flex flex-row justify-content-between ${
          screen === myScreen ? "list-group-item-selected" : "list-group-item"
        } list-group-item-action list-group-item-light p-3`}
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={() => dispatch(actionScreenUpdate(myScreen))}
      >
        {isExpanded ? <span className="active">{title}</span> : null}
        <Icon />
      </div>
    </Link>
  );
};
function Sidebar() {
  const dispatch = useDispatch();
  const screen = useSelector((state) => state.actionScreen);
  const isMobile = window.innerWidth < 766;

  const [isExpanded, setExpanded] = useState(
    isMobile ? true : localStorage.getItem("isExpanded") === "true"
  );

  const handleToggleSidebar = () => {
    if (!isMobile) {
      localStorage.setItem("isExpanded", String(!isExpanded));
      setExpanded(!isExpanded);
    }
  };
  return (
    <div
      id="sidebar-wrapper"
      className={`${isExpanded ? "expanded" : "collapsed"}`}
      style={{
        overflowY: "visible",
        overflowX: "hidden",
        height: "100vh",
        width: "100%",
        maxWidth: "295px",
      }}
    >
      <div
        className={`sidebar-heading border-bottom text-center header-navbar d-flex justify-content-between align-items-center`}
        style={{ gap: "10px" }}
      >
        {isExpanded ? (
          <a>
            <img src={Logo} className="logo" width={200} alt="logo" />
          </a>
        ) : null}
        <FaBars style={{ cursor: "pointer" }} onClick={handleToggleSidebar} />
      </div>
      <div className="list-group">
        <ul className="dropdown-menu-list">
          <li className="dropdown">
            {items.map((each) => {
              const { title, Icon, to, myScreen } = each;
              return (
                <Item
                  screen={screen}
                  title={title}
                  Icon={Icon}
                  dispatch={dispatch}
                  isExpanded={isExpanded}
                  to={to}
                  myScreen={myScreen}
                />
              );
            })}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
