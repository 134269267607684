/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment";
import Navigation from "../Navigation";
import Footer from "../../Footer";
import Sidebar from "../Sidebar/index";
import {
  actionScreenUpdate,
  hideLoader,
  showLoader,
} from "../../../redux/actions";
import { useAPI } from "../../../Api/context";
import JobDetails from "./JobDetails";
import { runOncePerDay, timeSheetComponents, timeToMs } from "./utils";
import "../../../css/style.css";
import "../../../css/bootstrap.min.css";
import Stopwatch from "./stopWatch";
import JobRunner from "./JobRunner";

function EmployeeTimeSheet() {
  const {
    getJobByEmail,
    getEmployeeById,
    updateStatus,
    upDateWorkingHours,
    updateLastRunDate,
    updateTimer,
  } = useAPI();
  const [state, setState] = useState({});
  const [component, setComponent] = useState(timeSheetComponents.initial);
  const [jobDetails, setJobDetails] = useState({});
  const [jobs, setJobs] = useState([]);
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(timeToMs(state.todayshour));
  const [isRunning, setIsRunning] = useState("");

  const userId = localStorage.getItem("id");
  const dispatch = useDispatch();

  const getEmployee = () => {
    const promise = getEmployeeById(userId);
    promise
      .then((response) => {
        setState(response.data.list[0]);
        const runned = runOncePerDay(
          response.data.list[0],
          upDateWorkingHours,
          updateLastRunDate
        );
        if (runned === 1) {
          window.location.reload();
          localStorage.removeItem("elapsedTime");
        }
        const job = getJobByEmail(response.data.list[0].email);
        job
          .then((data) => {
            setJobs(data);
            const isOngoing = data.some((item) => item.status === "ongoing");
            if (isOngoing) {
              setIsRunning(true);
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      })
      .catch((err) => {
        alert(err.message);
      });
  };
  useEffect(() => {
    dispatch(actionScreenUpdate("EMPLOYEE_TIME_SHEET"));
    dispatch(showLoader());
    getEmployee();
    dispatch(hideLoader());
  }, []);

  const onDetailClick = (data) => {
    setJobDetails(data);
    setComponent(timeSheetComponents.jobDetails);
  };
  const updateJobStatus = async (data) => {
    if (data.status === "ongoing") {
      const isOngoing = jobs.some((item) => item.status === "ongoing");
      if (isOngoing) {
        alert("Pause The OnGoing Job");
        return;
      }
    }
    const response = await updateStatus(data);
    if (response.data.status === 1) {
      const UpdatedJob = jobs.map((each) => {
        if (each.id === data.id) {
          if (data.status === "ongoing") {
            setStartTime(Date.now() - elapsedTime);
            setIsRunning(true);
          } else {
            setIsRunning(false);
          }
          return { ...each, status: data.status };
        }
        return each;
      });
      setJobs(UpdatedJob);
    } else {
      alert(response.data.message);
    }
  };

  useEffect(() => {
    let intervalId;
    if (isRunning) {
      if (state.intervalId === "null") {
        intervalId = setInterval(() => {
          getEmployee();
        }, 60000);
      }
    } else {
      if (isRunning === false) {
        clearInterval(intervalId);
      }
    }
    getEmployee();
    return () => clearInterval(intervalId);
  }, [isRunning]);
  return (
    <React.Fragment>
      <div
        className="d-flex h-dashboard bg client"
        id="wrapper"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Sidebar />
        <div id="page-content-wrapper" className="height-overflow">
          <Navigation name={state.first_name} state={state} />
          <div className="container-fluid">
            {component.timeSheet && state.id && jobs.length > 0 && (
              <div>
                {/* <Stopwatch data={{ state, isRunning }} /> */}
                <div className="container ps-4 pe-4 mt-0 border border-2 rounded-1">
                  <div className="row">
                    <div className="col bg-light">
                      <h1 className="text-start page-heading m-3">
                        Today's Job
                      </h1>
                    </div>
                  </div>
                  <div className="row border-bottom text-center mt-3 pb-3">
                    <div className="col-2">
                      <div className="fw-bolder opacity-75">Job Number</div>
                    </div>
                    <div className="col-2">
                      <div className="fw-bolder opacity-75">Title</div>
                    </div>
                    <div className="col-2">
                      <div className="fw-bold opacity-50">Time</div>
                    </div>
                    <div className="col-2">
                      <div className="fw-bold opacity-50">Time Taken</div>
                    </div>
                    <div className="col-2">
                      <div className="fw-bold opacity-50">Details</div>
                    </div>
                    <div className="col-2">
                      <div className="fw-bold opacity-50">Start</div>
                    </div>
                  </div>
                  {jobs.map((each) => {
                    const todaysDate = new Date();
                    const jobStartDate = new Date(each.start);
                    const jobEndDate = new Date(each.end);
                    const todaysDay = todaysDate.getDate();
                    const jobEndDay = jobEndDate.getDate();
                    if (
                      todaysDate >= jobStartDate &&
                      todaysDate <= jobEndDate
                    ) {
                      return (
                        <div
                          key={each.id}
                          className={`row border-bottom text-center pt-3 pb-3 ${
                            each.jobType === "Recurring Job" ? "bg-light" : ""
                          }`}
                        >
                          <div className="col-2">
                            <div className="fw-bolder opacity-75 text-success">
                              #{each.jobNumber}
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="fw-bolder opacity-75">
                              {each.title}
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="fw-bold opacity-50">{`${new Date(
                              each.start
                            ).toLocaleTimeString()}`}</div>
                          </div>
                          <div className="col-2">
                            <div className="fw-bold opacity-50">
                              <JobRunner data={{ each, updateJobStatus }} />
                            </div>
                          </div>
                          <div className="col-2">
                            <button
                              onClick={() => onDetailClick(each)}
                              className="fw-bold opacity-50 btn btn-outline-info text-black border border-info border-2 rounded"
                            >
                              Details
                            </button>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            {each.status === "pending" && (
                              <button
                                onClick={() =>
                                  updateJobStatus({
                                    status: "ongoing",
                                    id: each.id,
                                  })
                                }
                                className="fw-bold opacity-50 btn btn-outline-success text-black border border-success border-2 rounded"
                              >
                                Start
                              </button>
                            )}
                            {each.status === "ongoing" && (
                              <button
                                onClick={() =>
                                  updateJobStatus({
                                    status: "complete",
                                    id: each.id,
                                  })
                                }
                                className="fw-bold opacity-50 btn btn-outline-success text-black border border-success border-2 rounded"
                              >
                                Complete
                              </button>
                            )}
                            {each.status === "complete" && (
                              <div className="fw-bolder opacity-50 text-success">
                                Completed
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <div className="container ps-4 pe-4 mt-0 border border-2 rounded-1">
                  <div className="row">
                    <div className="col bg-light">
                      <h1 className="text-start page-heading m-3">
                        Previous Job
                      </h1>
                    </div>
                  </div>
                  <div className="row border-bottom text-center mt-3 pb-3">
                    <div className="col-2">
                      <div className="fw-bolder opacity-75">Job Number</div>
                    </div>
                    <div className="col-2">
                      <div className="fw-bolder opacity-75">Title</div>
                    </div>
                    <div className="col-2">
                      <div className="fw-bold opacity-50">Date</div>
                    </div>
                    <div className="col-2">
                      <div className="fw-bold opacity-50">Time Taken</div>
                    </div>
                    <div className="col-2">
                      <div className="fw-bold opacity-50">Details</div>
                    </div>
                    <div className="col-2">
                      <div className="fw-bold opacity-50">Start</div>
                    </div>
                  </div>
                  {jobs.map((each) => {
                    const todaysDate = new Date();
                    const jobDate = new Date(each.start);
                    const jobEndDate = new Date(each.end);
                    let timing = `${new Date(each.start).toLocaleDateString()}`;
                    if (todaysDate > jobDate && todaysDate > jobEndDate) {
                      return (
                        <div
                          key={each.id}
                          className={`row border-bottom text-center pt-3 pb-3 ${
                            each.jobType === "Recurring Job" ? "bg-light" : ""
                          }`}
                        >
                          <div className="col-2">
                            <div className="fw-bolder opacity-75 text-success">
                              #{each.jobNumber}
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="fw-bolder opacity-75">
                              {each.title}
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="fw-bold opacity-50">{timing}</div>
                          </div>
                          <div className="col-2">
                            <div className="fw-bold opacity-50">
                              <JobRunner data={{ each, updateJobStatus }} />
                            </div>
                          </div>
                          <div className="col-2">
                            <button
                              onClick={() => onDetailClick(each)}
                              className="fw-bold opacity-50 btn btn-outline-info text-black border border-info border-2 rounded"
                            >
                              Details
                            </button>
                          </div>
                          <div className="col-2 d-flex justify-content-center">
                            {each.status === "pending" && (
                              <button
                                onClick={() =>
                                  updateJobStatus({
                                    status: "ongoing",
                                    id: each.id,
                                  })
                                }
                                className="fw-bold opacity-50 btn btn-outline-success text-black border border-success border-2 rounded"
                              >
                                Start
                              </button>
                            )}
                            {each.status === "ongoing" && (
                              <button
                                onClick={() =>
                                  updateJobStatus({
                                    status: "complete",
                                    id: each.id,
                                  })
                                }
                                className="fw-bold opacity-50 btn btn-outline-success text-black border border-success border-2 rounded"
                              >
                                Complete
                              </button>
                            )}
                            {each.status === "complete" && (
                              <div className="fw-bolder opacity-50 text-success">
                                Completed
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>

                <div className="container ps-4 pe-4 mt-0 border border-2 rounded-1">
                  <div className="row">
                    <div className="col bg-light">
                      <h1 className="text-start page-heading m-3">
                        This Month Job
                      </h1>
                    </div>
                  </div>
                  <div className="row border-bottom text-center mt-3 pb-3">
                    <div className="col-3">
                      <div className="fw-bolder opacity-75">Job Number</div>
                    </div>
                    <div className="col-3">
                      <div className="fw-bolder opacity-75">Title</div>
                    </div>
                    <div className="col-3">
                      <div className="fw-bold opacity-50">Date</div>
                    </div>
                    <div className="col-3">
                      <div className="fw-bold opacity-50">Details</div>
                    </div>
                  </div>
                  {jobs.map((each) => {
                    const todaysMonth = new Date().getMonth();
                    const todaysyear = new Date().getFullYear();
                    const jobMonth = new Date(each.start).getMonth();
                    const jobYear = new Date(each.start).getFullYear();
                    let timing = `${new Date(each.start).toLocaleDateString()}`;
                    {
                      /* if(each.jobType==='Recurring Job') timing=`${new Date(each.start).toLocaleDateString()} - ${new Date(each.end).toLocaleDateString()}` */
                    }
                    if (todaysyear === jobYear && todaysMonth === jobMonth) {
                      return (
                        <div
                          key={each.id}
                          className={`row border-bottom text-center pt-3 pb-3 ${
                            each.jobType === "Recurring Job" ? "bg-light" : ""
                          }`}
                        >
                          <div className="col-3">
                            <div className="fw-bolder opacity-75 text-success">
                              #{each.jobNumber}
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="fw-bolder opacity-75">
                              {each.title}
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="fw-bold opacity-50">{timing}</div>
                          </div>
                          <div className="col-3">
                            <button
                              onClick={() => onDetailClick(each)}
                              className="fw-bold opacity-50 btn btn-outline-info text-black border border-info border-2 rounded"
                            >
                              Details
                            </button>
                          </div>
                        </div>
                      );
                    } else {
                      return null;
                    }
                  })}
                </div>
                <div className="container ps-4 pe-4 mt-0 border border-2 rounded-1">
                  <div className="row">
                    <div className="col bg-light">
                      <h1 className="text-start page-heading m-3">All Jobs</h1>
                    </div>
                  </div>
                  <div className="row border-bottom text-center mt-3 pb-3">
                    <div className="col-3">
                      <div className="fw-bolder opacity-75">Job Number</div>
                    </div>
                    <div className="col-3">
                      <div className="fw-bolder opacity-75">Title</div>
                    </div>
                    <div className="col-3">
                      <div className="fw-bold opacity-50">Date</div>
                    </div>
                    <div className="col-3">
                      <div className="fw-bold opacity-50">Details</div>
                    </div>
                  </div>
                  {jobs.map((each) => {
                    let timing = `${new Date(each.start).toLocaleDateString()}`;
                    return (
                      <div
                        key={each.id}
                        className={`row border-bottom text-center pt-3 pb-3 ${
                          each.jobType === "Recurring Job" ? "bg-light" : ""
                        }`}
                      >
                        <div className="col-3">
                          <div className="fw-bolder opacity-75 text-success">
                            #{each.jobNumber}
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="fw-bolder opacity-75">
                            {each.title}
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="fw-bold opacity-50">{timing}</div>
                        </div>
                        <div className="col-3">
                          <button
                            onClick={() => onDetailClick(each)}
                            className="fw-bold opacity-50 btn btn-outline-info text-black border border-info border-2 rounded"
                          >
                            Details
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {component.details && (
              <JobDetails data={{ setComponent, jobDetails }} />
            )}
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default EmployeeTimeSheet;
