import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";

const Model = (props) => {
  const { show, setShow, header, data, navigate, deleteJob, jobDetails } = props.data;
  const [deleteBtn, setDeleteBtn] = useState(false);
  const [deleteRecursive, setDeleteRecursive] = useState(false);
  const navigateTO = useNavigate();
  const handleClose = () => {
    setShow(false);
    if (navigate) navigateTO(navigate);
  };
  const handleDeleteClose = () => {
    setDeleteBtn(false);
  };
  const handleCheckbox=()=>{
    setDeleteRecursive(!deleteRecursive);
  }
  return (
    <div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{header}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{data && data()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {deleteJob && (
            <>
              <Button variant="danger" onClick={() => setDeleteBtn(true)}>Delete</Button>
              <Modal show={deleteBtn} onHide={handleDeleteClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Delete Job</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                 <div className="text-center">Are you sure you want to Delete this Job</div> 
                 {jobDetails?.jobType==="Recurring Job" && <div className="text-center" >
                  <input class="form-check-input me-2 " style={{cursor:"pointer"}} type="checkbox" value={deleteRecursive} id="flexCheckDefault" onChange={handleCheckbox}/>
                  <label class="form-check-label text-danger " style={{cursor:"pointer"}} for="flexCheckDefault">Delete all this recurring Jobs?</label>
                 </div>}
                </Modal.Body>
                <Modal.Footer>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={handleDeleteClose}
                  >
                    NO
                  </button>
                  <button
                    onClick={()=>{
                      deleteJob(deleteRecursive)
                      handleDeleteClose()
                    }}
                    type="button"
                    className="btn btn-danger"
                  >
                    Yes
                  </button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Model;
