import React from 'react'
import Logo from '../../images/logo.png'
import Navigation from '../Navigation'
import Footer from '../Footer'

function Profile() {
  return (
    <div className="h-dashboard bg text-center">
        <Navigation />
        <div className="container Profile">
          <div className="text-center">
            <a href="dashboard.html">
              <img src={Logo} className="logo" alt="logo" />
            </a>
          </div>
          <h2>Welcome <span>Jhone Martin</span></h2>
          <div className="Profile-preview">
            <img src="images/man.png" className="man" alt="user" />
          </div>
          <h3>Jhone Martin</h3>
          <h6>jhonemartin@gmail.com</h6>
          <h4>Service Head in Cleaning Department</h4>
          <table className="b-0 info-table">
            <tbody className="text-center">
              <tr>
                <td>Gender</td>
                <td>:</td>
                <td>Male</td>
              </tr>
              <tr>
                <td>Mobile Number</td>
                <td>:</td>
                <td>758 5856 566</td>
              </tr>
              <tr>
                <td>Address </td>
                <td>:</td>
                <td>204 Park Avenue, NY USA 10166</td>
              </tr>
            </tbody>
          </table>
          <a href="dashboard.html" type="button" className="dashboard-btn">Go to Dashboard</a>
        </div>
      {/* <Footer /> */}
    </div>
  )
}

export default Profile