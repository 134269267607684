import moment from 'moment';
export const timeSheetComponents={
initial:{
    timeSheet:true,
    details:false
  },
  jobDetails:{
    timeSheet:false,
    details:true
  }
}
export const constants={
  status:{
    complete:"complete",
    pending:"pending",
    ongoing:"ongoing",
    paused:"paused"
  }
}
export const timeToMs = (timeString) => {
  if(!timeString)timeString="00:00:00";
  return parseInt(moment.duration(timeString).asMilliseconds());
};
export function runOncePerDay(employeeDetails,upDateWorkingHours,updateLastRunDate) {
  const today = new Date().toDateString();
  const {lastUpdated,id,currentmonthhour,todayshour}=employeeDetails
  if (lastUpdated !== today) {
    if(new Date().getMonth()!==new Date(lastUpdated).getMonth()){
       upDateWorkingHours({
        todayshour:"00:00",
        currentmonthhour:"00:00",
        id:id,
        previousmonthhour:currentmonthhour
      })
      updateLastRunDate({id:id,lastUpdated:today})
      return 1;
    }else{
      const totalMonthTime=moment.utc(timeToMs(todayshour)+timeToMs(currentmonthhour)).format('HH:mm:ss');
      upDateWorkingHours({todayshour:"00:00:00","currentmonthhour":totalMonthTime,id:id})
      updateLastRunDate({id:id,lastUpdated:today})
      return 1;
    }
  } else {
    return 0;
  }
}
