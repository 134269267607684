import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker, InfoWindow } from "google-maps-react";
import Loader from "../Loader";
import "../../css/style.css"

const googleMapsApiKey = "AIzaSyCAOi56gyn5pstOqZJvYT_GjQf007-kbkU";

const MapContainer = (props) => {
  console.log(props);
  const {setAddress,google,getCurrentPosition}=props
  const [position, setPosition] = useState({
    lat:0,
    lng:0
  });
  const getAdress=async()=>{
    const {lat,lng}=position;
    console.log(position)
    try {
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${googleMapsApiKey}`);
      const data = await response.json();
      setAddress(data.results[0].formatted_address);
    } catch (error) {
      console.log('Error:', error);
    }
  }
  const handleMapClick = async (mapProps, map, clickEvent) => {
    const lat = clickEvent.latLng.lat();
    const lng = clickEvent.latLng.lng();
    setPosition({lat,lng})
  };

  const getLocation=()=>{
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude
        });
      });
    }
  }

  useEffect(() => {
    getLocation()
  }, [getCurrentPosition]);

  

  useEffect(()=>{
    getAdress();
  },[position.lat,position.lng])

  return (
    <div>
      <button className="btn btn-light" onClick={getLocation}>Current Location</button>
      <Map
        google={google}
        zoom={10}
        initialCenter={position}
        center={position}
        onClick={handleMapClick}
        className="map"
      >
        <Marker position={position} /></Map>
    </div>
  );
};
const LoadingContainer = () => <Loader/>;

export default GoogleApiWrapper({
  apiKey: (googleMapsApiKey),
  LoadingContainer:LoadingContainer
})(MapContainer);
