import React, { useState } from 'react'
import { useAPI } from "../../Api/context";


const EditDepartment = (props) => {
    const { editDepartment } = useAPI();
    var departmentObj = props.departmentLists[props.pos];
    const handleClose = props.popup
    const refersh = props.refresh
    const [departmentName, setDepartmentName] = useState(departmentObj.name)
    const [error, setError] = useState({ departmentError: false });
    const handleChangeDepartment = (e) => { setDepartmentName(e.target.value.trim()) }


    const clearData = () => {
        setDepartmentName('')
    }

    const EditDepartmentName = () => {
        setError({
            departmentError: false
        })

        if (departmentName === '') {
            setError((prevState) => ({
                ...prevState,
                departmentError: true,
            }));
            return;

        }
        else {
            handleClose()
            const data={ id: departmentObj.id, name: departmentName }
            const response = editDepartment(data);
            response.then((responseData) => {
              if (responseData===true) {
                clearData()
                refersh()
                alert("Edited successfully.")
              } else {
                alert(responseData)
              }
            }
            )
        }
    }

    return (
        <div id="page-content-wrapper">
            <div className="container-fluid">
                <div className="col-md-12">
                    <form className="row">

                        <div className="col-md-6">
                            <input value={departmentName} onChange={handleChangeDepartment} type="text" className="form-control" placeholder="First name" aria-label="First name" />
                            {error.departmentError ?
                                <div className="add-employee-error">
                                    Please enter the department name.
                                </div> : null}
                        </div>

                        <div className="col-md-12">
                            <button onClick={EditDepartmentName} type="button" className="btn btn-primary add" data-bs-toggle="modal" data-bs-target="#exampleModal">Update</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    )
}

export default EditDepartment;