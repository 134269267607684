/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import Navigation from "../Navigation";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import axios from "axios";
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import "../../css/dashboard.css";
import pen from "../../images/pen.png";
import dustbin from "../../images/dustbin.png";
import search from "../../images/search.png";
import { useDispatch } from "react-redux";
import {
  actionScreenUpdate,
  hideLoader,
  showLoader,
} from "../../redux/actions";
import { useAPI } from "../../Api/context";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import EditClient from "./EditClient";

function ClientsList() {
  const { getClient, clients, deleteClient } = useAPI();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [selPosition, setSelPosition] = useState(0);
  const [clientData, setClientData] = useState({});
  useEffect(() => {
    dispatch(showLoader());
    getClient();
    dispatch(actionScreenUpdate("CLIENTS"));
    dispatch(hideLoader());
  }, []);
  const handleEditClick = (data, index) => {
    setSelPosition(index);
    setClientData(data);
    handleShow();
  };
  const handleDeleteClick = (id) => {
    deleteClient(id);
  };

  return (
    <div className="d-flex h-dashboard bg" id="wrapper">
      <Sidebar />
      <div id="page-content-wrapper">
        <Navigation />
        <div className="container-fluid employee-table">
          <form className="row">
            <div className="col-md-6">
              <div className="wrap">
                <h6 className="input-group-prepend mb-2">&nbsp;</h6>
                <div className="search">
                  <input
                    type="text"
                    className="searchTerm form-select"
                    placeholder="Search Clients"
                  />
                  <button type="submit" className="searchButton">
                    <span className="search-icon">
                      <img src={search} className="search" alt="search" />
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div className="col-md-2">
              <h6 className="input-group-prepend mb-2">SORT</h6>
              <div className="input-group-prepend mb-0">
                <label className="visually-hidden" htmlFor="autoSizingSelect">
                  Main
                </label>
                <select className="form-select" id="autoSizingSelect">
                  <option value="Choose" defaultValue>
                    First Name
                  </option>
                  <option value="1">Maya</option>
                  <option value="2">Jhon</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <h6 className="input-group-prepend mb-2">FILTER</h6>
              <div className="input-group-prepend mb-0">
                <label className="visually-hidden" htmlFor="autoSizingSelect">
                  Main
                </label>
                <select className="form-select" id="autoSizingSelect">
                  <option value="Choose" defaultValue>
                    Leads and Active
                  </option>
                  <option value="1">Leads and Active</option>
                  <option value="2">Leads and Active</option>
                </select>
              </div>
            </div>
            <div className="col-md-2">
              <h6 className="input-group-prepend mb-2">&nbsp;</h6>
              <button
                type="button"
                className="btn btn-primary add table-btn"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
              >
                <Link to={"/ADD_CLIENT"} className="custom-link">
                  Add Client
                </Link>
              </button>
            </div>
          </form>

          <div className="table-responsive dashboard-table mt-4">
            <table className="table table-light">
              <thead className="shadow">
                <tr>
                  <th>Company</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>ACTION</th>
                </tr>
              </thead>
              <tbody>
                {clients.map((item, index) => (
                  <tr key={index}>
                    <td>{item.companyName}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.address}</td>
                    <td>
                      <div className="d-flex gap-1">
                        <button
                          type="button"
                          className="btn btn-primary custom-btn me-2"
                          onClick={() => handleEditClick(item, index)}
                        >
                          Edit
                        </button>
                        <button
                          type="button"
                          className="btn btn-danger custom-btn"
                          onClick={() => handleDeleteClick(item.id)}
                        >
                          Delete
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <Footer />
        <Modal fullscreen={true} show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditClient pos={selPosition} popup={handleClose} data={clientData} />
          </Modal.Body>
        </Modal>
      </div>
    </div>
  );
}

export default ClientsList;
