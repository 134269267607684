import React from "react";
import { timeSheetComponents } from "./utils";

const JobDetails = (props) => {
    const { setComponent, jobDetails } = props.data;
    const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
    const REACT_APP_FILE_PATH = process.env.REACT_APP_FILE_PATH;
    
    return (
        <div className="container mt-0">
                <button className="btn text-white btn-primary add mb-4" onClick={() => setComponent(timeSheetComponents.initial)}>Time Sheet</button>
            <div className="col-md-12">
                <div className="row">
                    <div className="col-md-12">
                        <h2 className="page-heading client-heading mb-4">JOB DETAILS</h2>
                    </div>

                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Title: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.title}</p>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Job Number: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.jobNumber}</p>
                        </div>
                    </div>


                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Client Email: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.jobBy}</p>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Job Type: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.jobType}</p>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center gap-4 ">
                            <h4>Employee Email: </h4>
                            <p className="mb-0 text-muted font-weight-bolder">
                                <ol  >
                                    {jobDetails.jobFor.map((data, i) => {
                                        return (<li key={i}>{data}</li>)
                                    })}
                                </ol>
                            </p>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Status: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.status.toUpperCase()}</p>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Instructions: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.discription}</p>
                        </div>
                    </div>

                    <div className="col-md-12">
                        <h4 className="page-heading client-heading mb-4 ">Address</h4>
                    </div>

                    {/* <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>City: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.city}</p>
                        </div>
                    </div> */}

                    <div className="col-md-12">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Address: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.street}</p>
                        </div>
                    </div>

                    {/* <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Land Mark: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.landMark}</p>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Postal Code: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.postalCode}</p>
                        </div>
                    </div> */}

                    <div className="col-md-12">
                        <h4 className="page-heading client-heading mb-4 ">Timing</h4>
                    </div>

                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Start: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.start ? new Date(jobDetails.start).toLocaleString():"Not Available" }</p>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>End: </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.end ? new Date(jobDetails.end).toLocaleString() : "Not Available"}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Time Taken(HH:MM:SS): </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.time || "00:00:00"}</p>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="custom-heading d-flex justify-content-start align-items-center">
                            <h4>Max Hours(HH:MM): </h4>
                            <p className="mb-1 text-muted font-weight-bolder">{jobDetails.maxhours || "00:00"}</p>
                        </div>
                    </div>

                    {jobDetails.healthandsafetycheck && (
            <>
              <div className="col-md-12">
                <h4 className="page-heading client-heading mb-4 ">Documents</h4>
              </div>
              <div className="col-md-6">
                  <div
                    className="card border-0 m-0 bg-gray"
                    style={{ backgroundColor: "#004169" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i className="fal fa-file-pdf"></i>
                        </div>
                        <div className="flex-grow-1 ms-3 w-50 text-white">
                          Health & Safety Check
                        </div>
                        <div className="flex-shrink-0">
                          <a
                            href={
                              REACT_APP_API_URL +
                              REACT_APP_FILE_PATH +
                              jobDetails.healthandsafetycheck
                            }
                            target="_blank"
                            className="btn btn-link text-white"
                            rel="noreferrer"
                          >
                            View
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
              </div>
            </>
          )}
                </div>
            </div>
        </div>
    );
};

export default JobDetails;
