import ScreenReducer from './ScreenUpdater'
import loadingReducer from './isLoading'
import { combineReducers } from 'redux'

const allReducers = combineReducers({
    actionScreen: ScreenReducer,
    isLoading: loadingReducer,
})

export default allReducers
