const loadingReducer = (state = false, action) => {
    switch (action.type) {
        case 'SHOW_LOADING' :
            return !state;

        case 'HIDE_LOADING' :
            return !state;
            
        default:
            return state;
    }
}

export default loadingReducer