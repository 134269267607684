import React, { useEffect, useState } from 'react'
import { useDispatch } from "react-redux";
import { actionScreenUpdate, hideLoader, showLoader } from '../../redux/actions'
import Navigation from '../Navigation'
import Footer from '../Footer'
import Sidebar from '../Sidebar'
import axios from 'axios'
import { Modal, Button, Card } from 'react-bootstrap';
import '../../css/style.css'
import '../../css/bootstrap.min.css'
import '../../css/dashboard.css'
import EditEmployee from '../EditEmployee'
import { useAPI } from '../../Api/context';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomAccordion from './Accordion';
import Accordion from "./Accordion.js";
const accordionItems = [
  {
    title: 'Accordion Item 1',
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  },
  {
    title: 'Accordion Item 2',
    content:
      'Sed ut perspiciatis unde omnis iste natus error sit voluptatem.',
  },
  {
    title: 'Accordion Item 3',
    content:
      'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis.',
  },
];

function EmployeesList() {
  const { deleteEmployee,employees,getEmployee } = useAPI();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [selPosition, setSelPosition] = useState(0)

  const handleDeleteClick = (employeeId) => {
   const data={id:employeeId}
   deleteEmployee(data)
  };

  const handleEditClick = (employeeId, index) => {
    setSelPosition(index)
    handleShow()
  };

  useEffect(() => {
    dispatch(showLoader());
    getEmployee();
    dispatch(actionScreenUpdate("EMPLOYEE"));
    dispatch(hideLoader());
  }, [])

  return (
    <div className="d-flex h-dashboard bg" style={{ height: "100vh", overflow: "hidden" }} id="wrapper">
      <Sidebar />
      <div id="page-content-wrapper" className='height-overflow'>
        <Navigation />
        <div className="container-fluid employee-table">
          <div className='row border-bottom text-center mt-3 pb-3'>
                <div className='col-2'>
                  <div className='fw-bolder opacity-75'>Id</div>
                </div>
                <div className='col-3'>
                  <div className='fw-bolder opacity-75'>Name</div>
                </div>
                <div className='col-4'>
                  <div className='fw-bold opacity-50'>Email</div>
                </div>
                <div className='col-2'>
                  <div className='fw-bold opacity-50'>Status</div>
                </div>
                <div className='col-1'>
                  <div className='fw-bold opacity-50'></div>
                </div>
              </div>
              <div className='d-flex flex-column gap-2'>
                {employees.map((employee,index) =>{
                  return <Accordion key={employee.id} employeeDetail={employee} handleEditClick={handleEditClick} handleDeleteClick={handleDeleteClick} index={index}/>
                })}
              </div>
          </div>
        <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>
                          Edit Employee
                        </Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <EditEmployee pos={selPosition} employeeList={employees} popup={handleClose} refresh={getEmployee} />
                      </Modal.Body>

                    </Modal>
        <Footer />
      </div>
    </div>
  )
}

export default EmployeesList