import React from "react";
import Sidebar from "../Sidebar";
import Navigation from "../Navigation";
import Footer from "../Footer";

const index = () => {
  return (
    <React.Fragment>
      <div className="d-flex h-dashboard bg client" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <Navigation />
          <div className="container-fluid">Expense</div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default index;
