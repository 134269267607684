/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { BsChevronRight } from "react-icons/bs";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
} from "mdb-react-ui-kit";
import user from "../../images/users.png";
import { useAPI } from "../../Api/context";
import { timeSheetComponents } from "../Employee/TimeSheet/utils";
import JobDetails from "../Employee/TimeSheet/JobDetails";

function Accordion(props) {
  const { getJobByEmail } = useAPI();
  const { careerDetail } = props;
  const [jobs, setJobs] = useState([]);
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");
  const [component, setComponent] = useState(timeSheetComponents.initial);
  const [jobDetails, setJobDetails] = useState({});
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_FILE_PATH = process.env.REACT_APP_FILE_PATH;

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "accordionActive" : "");
    setHeightState(setActive === "accordionActive" ? "0px" : `100%`);
    setRotateState(
      setActive === "accordionActive"
        ? "accordion__icon"
        : "accordion__icon rotate"
    );
  }

  useEffect(() => {
  }, []);

  const onDetailClick = (data) => {
    setJobDetails(data);
    setComponent(timeSheetComponents.jobDetails);
  };

  return (
    <div className="accordion__section">
      <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <div
          key={careerDetail.id}
          className={`row border-bottom text-center pt-3 pb-3`}
        >
          <div className="col-2">
            <div className="fw-bolder opacity-75" style={{ color: "#004169" }}>
              {careerDetail.id}
            </div>
          </div>
          <div className="col-3">
            <div className="fw-bolder opacity-75">
              {careerDetail.name}
            </div>
          </div>
          <div className="col-4">
            <div className="fw-bold opacity-50">{careerDetail.email}</div>
          </div>
          <div className="col-2">
            <div className="fw-bold opacity-50">{careerDetail.phone}</div>
          </div>
          <div className="col-1">
            <BsChevronRight
              className={`${setRotate}`}
              width={10}
              fill={"#777"}
            />
          </div>
        </div>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <Tabs defaultActiveKey="Profile" className="mb-3">
          <Tab tabClassName="Profile" eventKey="Profile" title="Applicant Details">
            <MDBContainer className="py-0">
              <MDBRow className="justify-content-center align-items-center h-100">
                <MDBCol lg="10" className="mb-4 mb-lg-0">
                  <MDBCard className="h-100" style={{ borderRadius: ".5rem" }}>
                    <MDBRow className="g-0">
                      <MDBCol md="12">
                        <MDBCardBody className="p-4">
                          <MDBTypography tag="h6">Information</MDBTypography>
                          <hr className="mt-0 mb-4" />
                          <MDBRow className="pt-1">
                          <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">Name</MDBTypography>
                              <MDBCardText className="text-muted">
                                {careerDetail.name}
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">Email</MDBTypography>
                              <MDBCardText className="text-muted">
                                {careerDetail.email}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="pt-1">
                          <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">Phone</MDBTypography>
                              <MDBCardText className="text-muted">
                                {careerDetail.phone}
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">Address</MDBTypography>
                              <MDBCardText className="text-muted">
                                {careerDetail.address}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="pt-1">
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">Position</MDBTypography>
                              <MDBCardText className="text-muted">
                                {careerDetail.position}
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">License</MDBTypography>
                              <MDBCardText className="text-muted">
                                {careerDetail.license}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </Tab>
          <Tab tabClassName="documents" eventKey="documents" title="Resume">
            <div className="container-fluid">
              <div
                className="container Profile "
                style={{ padding: "0px", margin: "0px" }}
              >
                {careerDetail.resume && (
                  <div
                    className="card border-0 m-5 bg-gray"
                    style={{ backgroundColor: "#004169" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i className="fal fa-file-pdf"></i>
                        </div>
                        <div className="flex-grow-1 ms-3 w-50 text-white">
                          Resume
                        </div>
                        <div className="flex-shrink-0">
                          <a
                            href={careerDetail.resume}
                            target="_blank"
                            className="btn btn-link text-white"
                          >
                            View
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Accordion;
