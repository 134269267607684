/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Navigation from "../Navigation";
import EmployeeSidebar from "../Sidebar";
import { BiPencil } from "react-icons/bi";
import {
  actionScreenUpdate,
  hideLoader,
  showLoader,
} from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import "../../../css/style.css";
import "../../../css/bootstrap.min.css";
import { Link } from "react-router-dom";
import AlertModal from "../../AlertModel";
import LocationSearchInput from "../../Adress";

function EditEmployeeProfile() {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_FILE_PATH = process.env.REACT_APP_FILE_PATH;
  const initialState = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    zipcode: "",
    image: "",
    filenda: "",
    filecontract: "",
    // streetAddress: "",
    city: "",
    country: "",
    state: "",
    password: "",
    Confirmpassword: "",
    documentsigned: false,
  };
  const initialStateError = {
    firstname: false,
    lastname: false,
    email: false,
    phone: false,
    zipcode: false,
    image: false,
    filenda: false,
    filecontract: false,
    streetAddress: false,
    city: false,
    country: false,
    state: false,
    password: false,
    Confirmpassword: false,
    documentsigned: false,
  };
  const [show, setShow] = useState(false);
  const [state, setState] = useState(initialState);
  const [error, setError] = useState(initialStateError);
  const [address, setAddress] = useState("");
  const [data, setData] = useState({});
  const userId = localStorage.getItem("id");
  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.files) {
      setState((pre) => ({ ...pre, [e.target.name]: e.target.files[0].name }));
    } else if (e.target.type === "checkbox") {
      setState((pre) => ({ ...pre, [e.target.name]: e.target.checked }));
    } else {
      setState((pre) => ({ ...pre, [e.target.name]: e.target.value }));
    }
  };

  const clearData = () => {
    setState(initialState);
  };
  useEffect(() => {
    dispatch(actionScreenUpdate("EMPLOYEE_PROFILE"));
    dispatch(showLoader());
    async function fetchData() {
      axios
        .get(`${REACT_APP_API_URL}employees/get_employee_by_id?id=${userId}`)
        .then(function (response) {
          dispatch(hideLoader());
          const data = response.data.list[0];
          setState((pre) => ({
            ...pre,
            email: data.email,
            firstname: data.first_name,
            lastname: data.last_name,
            phone: data.phone_number,
          }));
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(err.message);
        });
    }
    fetchData();
  }, []);

  const AddEmployee = () => {
    setError(initialStateError);
    if (state.firstname === "") {
      setError((prevState) => ({
        ...prevState,
        firstname: true,
      }));
      return;
    } else if (state.lastname === "") {
      setError((prevState) => ({
        ...prevState,
        lastname: true,
      }));
      return;
    } else if (state.phone.length !== 11) {
      setError((prevState) => ({
        ...prevState,
        phone: true,
      }));
      return;
    }
    // else if (state.zipcode.length !== 6) {
    //   setError((prevState) => ({
    //     ...prevState,
    //     zipcode: true,
    //   }));
    //   return;
    // }
    else if (state.image === "") {
      setError((prevState) => ({
        ...prevState,
        image: true,
      }));
      return;
    } else if (state.filenda === "") {
      setError((prevState) => ({
        ...prevState,
        filenda: true,
      }));
      return;
    } else if (state.filecontract === "") {
      setError((prevState) => ({
        ...prevState,
        filecontract: true,
      }));
      return;
    } else if (address === "") {
      setError((prevState) => ({
        ...prevState,
        streetAddress: true,
      }));
      return;
    }
    // else if (state.city === "") {
    //   setError((prevState) => ({
    //     ...prevState,
    //     city: true,
    //   }));
    //   return;
    // } else if (state.country === "") {
    //   setError((prevState) => ({
    //     ...prevState,
    //     country: true,
    //   }));
    //   return;
    // } else if (state.state === "") {
    //   setError((prevState) => ({
    //     ...prevState,
    //     state: true,
    //   }));
    //   return;
    // }
    else if (state.password.length < 6) {
      setError((prevState) => ({
        ...prevState,
        password: true,
      }));
      return;
    } else if (state.Confirmpassword !== state.password) {
      setError((prevState) => ({
        ...prevState,
        Confirmpassword: true,
      }));
      return;
    } else if (state.documentsigned !== true) {
      setError((prevState) => ({
        ...prevState,
        documentsigned: true,
      }));
      return;
    } else {
      dispatch(showLoader());
      var formData = new FormData();
      const image = document.getElementById("imageUpload").files[0];
      const nda = document.getElementById("NDA").files[0];
      const contract = document.getElementById("contract").files[0];
      formData.append("image", image);
      formData.append("nda", nda);
      formData.append("contract", contract);
      formData.append("streetAddress", address);
      for (const property in state) {
        formData.append(property, state[property]);
      }
      formData.append("id", userId);
      axios
        .post(`${REACT_APP_API_URL}update_employee/edit_employee`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(function (response) {
          dispatch(hideLoader());
          if (response.data.status) {
            clearData();
            localStorage.setItem("profile_updated", true);
            setData((data) => ({
              ...data,
              header: "Profile Update",
              body: "Employee details updated successfully",
              navigate: "/EmployeeProfile",
            }));
            setShow(true);
          } else {
            setData((data) => ({
              ...data,
              body: "Failed!",
              header: response.data.message,
            }));
            setShow(true);
          }
        })
        .catch((err) => {
          dispatch(hideLoader());
          alert(err.message);
        });
    }
  };
  const readURL = (input) => {
    if (input.target.files && input.target.files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        var imagePreview = document.getElementById("imagePreview");
        imagePreview.style.backgroundImage = "url(" + e.target.result + ")";
        imagePreview.style.display = "none";
        setTimeout(function () {
          imagePreview.style.display = "block";
        }, 650);
      };
      reader.readAsDataURL(input.target.files[0]);
    }
  };

  return (
    <React.Fragment>
      <div
        className="d-flex h-dashboard bg client"
        id="wrapper"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <EmployeeSidebar />
        <div id="page-content-wrapper" className="height-overflow">
          <Navigation name={state.firstname} state={state} />
          <div className="container-fluid">
            <div className="container" style={{ padding: "0px" }}>
              <div className="picture-container">
                {state.firstname && state.lastname && (
                  <h2 className="text-center">
                    Welcome {state.firstname} {state.lastname}
                  </h2>
                )}
                <p className="text-center">
                  {" "}
                  To Proceed with your account, Please fill in following details
                </p>

                <div
                  className="container"
                  style={{
                    paddingTop: "0px",
                    paddingBottom: "0px",
                    marginTop: "0px",
                    marginBottom: "10px",
                  }}
                >
                  <div className="avatar-upload">
                    <div className="avatar-edit">
                      <input
                        type="file"
                        name="image"
                        id="imageUpload"
                        onChange={(e) => {
                          readURL(e);
                          handleChange(e);
                        }}
                        accept=".png, .jpg, .jpeg"
                      />
                      <label htmlFor="imageUpload">
                        <BiPencil />
                      </label>
                    </div>
                    <div className="avatar-preview">
                      <div
                        id="imagePreview"
                        style={{
                          backgroundImage: `url("http://i.pravatar.cc/500?img=7")`,
                        }}
                      ></div>
                    </div>
                  </div>
                  {error.image ? (
                    <div className="add-employee-error text-center mt-2">
                      Please Add Profile Image.
                    </div>
                  ) : null}
                </div>

                <form className="row">
                  <div className="col-md-6">
                    <input
                      value={state.firstname}
                      onChange={handleChange}
                      type="text"
                      name="firstname"
                      className="form-control"
                      placeholder="First name"
                      aria-label="First name"
                    />
                    {error.firstname ? (
                      <div className="add-employee-error">
                        Please enter the first name.
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <input
                      value={state.lastname}
                      onChange={handleChange}
                      type="text"
                      name="lastname"
                      className="form-control"
                      placeholder="Last name"
                      aria-label="Last name"
                    />
                    {error.lastname ? (
                      <div className="add-employee-error">
                        Please enter the last name.
                      </div>
                    ) : null}
                  </div>

                  <div className="col-12">
                    <input
                      value={state.email}
                      disabled={true}
                      type="email"
                      name="email"
                      className="form-control"
                      placeholder="Email Address"
                      id="inputEmail3"
                    />
                    {error.email ? (
                      <div className="add-employee-error">
                        Please enter a valid email.
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <input
                      value={state.phone}
                      onChange={handleChange}
                      type="number"
                      name="phone"
                      className="form-control"
                      placeholder="Phone Number"
                      id="inputphoneNumber"
                    />
                    {error.phone ? (
                      <div className="add-employee-error">
                        Please enter a valid Number.
                      </div>
                    ) : null}
                  </div>

                  {/* <div className="col-md-6">
                    <input
                      value={state.zipcode}
                      onChange={handleChange}
                      type="text"
                      name="zipcode"
                      className="form-control"
                      placeholder="Zip Code"
                      id="inputzipcode"
                    />
                    {error.zipcode ? (
                      <div className="add-employee-error">
                        Please enter a valid Zip Code.
                      </div>
                    ) : null}
                  </div> */}

                  <div
                    className="input-group-custom col-md-6"
                    style={{ height: "52px" }}
                  >
                    <input
                      type="file"
                      className="form-control-custom"
                      onChange={handleChange}
                      id="NDA"
                      name="filenda"
                    />
                    <label
                      className="input-group-text-custom bg-secondary-custom"
                      htmlFor="NDA"
                    >
                      Upload NDA
                    </label>
                    {error.filenda ? (
                      <div className="add-employee-error">
                        Please enter a valid NDA File.
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <div
                      className="card border-0"
                      style={{ backgroundColor: "#004169" }}
                    >
                      <div
                        className="card-body ps-3 pe-3"
                        style={{ padding: "6px" }}
                      >
                        <div className="d-flex align-items-center">
                          <div className="flex-grow-1 ms-3 w-50 text-white">
                            Contract
                          </div>
                          <div className="flex-shrink-0">
                            <a
                              href={
                                REACT_APP_API_URL +
                                REACT_APP_FILE_PATH +
                                "contract.pdf"
                              }
                              target="_blank"
                              className="btn btn-link text-white"
                            >
                              View
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="input-group-custom col-md-6">
                    <input
                      type="file"
                      className="form-control-custom"
                      onChange={handleChange}
                      id="contract"
                      name="filecontract"
                    />
                    <label
                      className="input-group-text-custom bg-secondary-custom"
                      htmlFor="contract"
                    >
                      Upload Signed Contract
                    </label>
                    {error.filecontract ? (
                      <div className="add-employee-error">
                        Please enter a valid Contract File.
                      </div>
                    ) : null}
                  </div>
                  {/* 
                  <div className="col-md-6 mt-4">
                    <input
                      value={state.streetAddress}
                      onChange={handleChange}
                      type="text"
                      name="streetAddress"
                      className="form-control"
                      placeholder="Street Address"
                      aria-label="Street Address"
                    />
                    {error.streetAddress ? (
                      <div className="add-employee-error">
                        Please enter the Valid Street Address.
                      </div>
                    ) : null}
                  </div> */}

                  {/* <div className="col-md-6 mt-4">
                    <input
                      value={state.city}
                      onChange={handleChange}
                      type="text"
                      name="city"
                      className="form-control"
                      placeholder="City"
                      aria-label="City"
                    />
                    {error.city ? (
                      <div className="add-employee-error">
                        Please enter the Valid City.
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <input
                      value={state.state}
                      onChange={handleChange}
                      type="text"
                      name="state"
                      className="form-control"
                      placeholder="State"
                      aria-label="State"
                    />
                    {error.state ? (
                      <div className="add-employee-error">
                        Please enter the Valid State.
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <input
                      value={state.country}
                      onChange={handleChange}
                      type="text"
                      name="country"
                      className="form-control"
                      placeholder="Country"
                      aria-label="Country"
                    />
                    {error.country ? (
                      <div className="add-employee-error">
                        Please enter the Valid Country.
                      </div>
                    ) : null}
                  </div> */}

                  <div className="col-md-6 mt-4">
                    <input
                      value={state.password}
                      onChange={handleChange}
                      type="password"
                      name="password"
                      className="form-control"
                      placeholder="Password"
                      aria-label="State"
                    />
                    {error.password ? (
                      <div className="add-employee-error">
                        Password Must be atleast 6 Character.
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-6 mt-4">
                    <input
                      value={state.Confirmpassword}
                      onChange={handleChange}
                      type="password"
                      name="Confirmpassword"
                      className="form-control"
                      placeholder="Confirm Password"
                      aria-label="Country"
                    />
                    {error.Confirmpassword ? (
                      <div className="add-employee-error">
                        Password Must be Same.
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-6">
                    <div className="content d-flex gap-1 align-items-center mb-0">
                      <h5>Address</h5>
                    </div>
                    <LocationSearchInput setAddress={setAddress} />
                    {error.streetAddress ? (
                      <div className="add-employee-error">
                        Please enter the Valid Street Address.
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-6 col-sm-12 content mb-1">
                    <div className="form-check form-switch form-switch-lg d-flex justify-content-between align-items-center">
                      <label
                        className="form-check-label"
                        htmlFor="documentsigned"
                      >
                        Signed Contract Uploaded?
                      </label>
                      <input
                        name="documentsigned"
                        onChange={handleChange}
                        checked={state.documentsigned}
                        className="form-check-input"
                        type="checkbox"
                        id="documentsigned"
                        disabled={state.filecontract === ""}
                      />
                    </div>
                    {error.documentsigned ? (
                      <div className="add-employee-error mt-0">
                        Please upload signed contract and check this checkbox.
                      </div>
                    ) : null}
                  </div>

                  <div className="col-md-12 mt-4 d-flex justify-content-around">
                    <button
                      onClick={(e) => AddEmployee()}
                      type="button"
                      className="btn btn-primary add"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Update
                    </button>
                    <Link
                      className="btn btn-primary add d-flex"
                      to={"/EmployeeProfile"}
                    >
                      Cancel
                    </Link>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AlertModal data={{ ...data, show, setShow }} />
    </React.Fragment>
  );
}

export default EditEmployeeProfile;
