import React, { useState } from "react";
import Logo from "../../../images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { actionScreenUpdate } from "../../../redux/actions";
import { Link } from "react-router-dom";
import { RxDashboard } from "react-icons/rx";
import { FaBars } from "react-icons/fa";
import { IoMdPersonAdd } from "react-icons/io";
import { BsPeopleFill } from "react-icons/bs";
import { IoBusiness } from "react-icons/io5";
import { ImProfile } from "react-icons/im";
import { IoCalendarNumber } from "react-icons/io5";
import { FaDollarSign } from "react-icons/fa";
import { FaRegFilePdf } from "react-icons/fa";
import "./style.css";

const items = [
  {
    title: "Profile",
    Icon: ImProfile,
    to: "/EmployeeProfile",
    myScreen: "EMPLOYEE_PROFILE",
  },
  {
    title: "Time Sheet",
    Icon: IoCalendarNumber,
    to: "/EmployeeTimeSheet",
    myScreen: "EMPLOYEE_TIME_SHEET",
  },
  {
    title: "Pay Roll",
    Icon: FaDollarSign,
    to: "/EmployeePayRoll",
    myScreen: "EMPLOYEE_PAY_ROLL",
  },
  {
    title: "Documents",
    Icon: FaRegFilePdf,
    to: "/EmployeeDocuments",
    myScreen: "EMPLOYEE_DOCUMENTS",
  },
];

const Item = ({ screen, title, Icon, dispatch, isExpanded, to, myScreen }) => {
  return (
    <Link className="text-white text-decoration-none" to={to}>
      <div
        className={`nav-link dropdown-toggle d-flex flex-row justify-content-between ${
          screen === myScreen ? "list-group-item-selected" : "list-group-item"
        } list-group-item-action list-group-item-light p-3`}
        id="navbarDropdown"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
        onClick={() => dispatch(actionScreenUpdate(myScreen))}
      >
        {isExpanded ? <span className="active">{title}</span> : null}
        <Icon />
      </div>
    </Link>
  );
};

function EmployeeSidebar() {
  const dispatch = useDispatch();
  const screen = useSelector((state) => state.actionScreen);
  const isMobile = window.innerWidth < 766;

  const [isExpanded, setExpanded] = useState(
    isMobile ? true : localStorage.getItem("isExpanded") === "true"
  );

  const handleToggleSidebar = () => {
    if (!isMobile) {
      localStorage.setItem("isExpanded", String(!isExpanded));
      setExpanded(!isExpanded);
    }
  };

  return (
    <div
      id="sidebar-wrapper"
      className={`${isExpanded ? "expanded" : "collapsedEmployee"}`}
    >
      <div
        className={`sidebar-heading border-bottom text-center header-navbar d-flex justify-content-between align-items-center`}
        style={{ gap: "10px" }}
      >
        {isExpanded ? (
          <a>
            <img src={Logo} className="logo" width={200} alt="logo" />
          </a>
        ) : null}
        <FaBars style={{ cursor: "pointer" }} onClick={handleToggleSidebar} />
      </div>
      <div className="list-group">
        <ul className="dropdown-menu-list">
          <li className="dropdown">
            {items.map((each) => {
              const { title, Icon, to, myScreen } = each;
              return (
                <Item
                  screen={screen}
                  title={title}
                  Icon={Icon}
                  dispatch={dispatch}
                  isExpanded={isExpanded}
                  to={to}
                  myScreen={myScreen}
                />
              );
            })}
          </li>
        </ul>
      </div>
    </div>
  );
}

export default EmployeeSidebar;
