import React, { useState, useRef, useEffect } from "react";
import moment from 'moment';
import { useAPI } from "../../../Api/context";

function JobRunner(props) {
  const {each,updateJobStatus}=props.data;
  const {updateJobTime}=useAPI()
  let initialTimer=0;
  if(parseInt(localStorage.getItem(`${each.id}_time`))){
    initialTimer=parseInt(localStorage.getItem(`${each.id}_time`));
  }else{
    if(each.time)initialTimer=parseInt(moment.duration(each.time).asMilliseconds()) || 0;
  }
  const [elapsedTime, setElapsedTime] = useState(initialTimer);
  const intervalRef = useRef(null);
  const pause = () => {
    clearInterval(intervalRef.current);
  };

  const resume = () => {
    intervalRef.current = setInterval(() => {
      setElapsedTime(prevElapsedTime => prevElapsedTime + 1000);
    }, 1000);
  };
  
  const jobTimeUpdate=(time)=>{
    const maxhours=parseInt(moment.duration(each.maxhours || "00:00").asMilliseconds());
    if(maxhours<=time){
      updateJobStatus({ status: "complete", id: each.id })
      updateJobTime({id:each.id, time:moment.utc(maxhours).format('HH:mm:ss')})
      localStorage.removeItem(`${each.id}_time`)
    }else{
      updateJobTime({id:each.id,time:moment.utc(time).format('HH:mm:ss')})
    }
  }

  useEffect(() => {
    if (each.status==="complete")localStorage.removeItem(`${each.id}_time`);
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload",handleBeforeUnload);
      clearInterval(intervalRef.current);
    };
  }, []);

useEffect(() => {
    if(each.status==="ongoing"){
        resume()
    }else{
        pause();
    }
}, [each.status]);

useEffect(() => {
  let intervalId;
  if (each.status==="ongoing") {
    intervalId = setInterval(() => {
      setElapsedTime((pre)=>{
        jobTimeUpdate(pre);
        return pre;
      })
    }, 30000);
  }
  return () => clearInterval(intervalId);
}, [each.status]);

const handleBeforeUnload = event => {
    setElapsedTime(prevElapsedTime => {
        event.returnValue = '';
        event.preventDefault();
        localStorage.setItem(`${each.id}_time`, prevElapsedTime);
        return prevElapsedTime;
    });
  };

  return (
    <div>{moment.utc(elapsedTime).format('HH:mm:ss') || "00:00"}</div>

  );
}
export default JobRunner;
