import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  actionScreenUpdate,
  hideLoader,
  showLoader,
} from "../../redux/actions";
import Navigation from "../Navigation";
import Footer from "../Footer";
import Sidebar from "../Sidebar";
import { Modal } from "react-bootstrap";
import "../../css/style.css";
import "../../css/bootstrap.min.css";
import "../../css/dashboard.css";
import EditEmployee from "../EditEmployee";
import { useAPI } from "../../Api/context";
import "bootstrap/dist/css/bootstrap.min.css";
import Accordion from "./Accordion.js";


function Careers() {
  const { getCareers, careers } = useAPI();
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [selPosition, setSelPosition] = useState(0);

  useEffect(() => {
    dispatch(showLoader());
    getCareers();
    dispatch(actionScreenUpdate("JobApplicants"));
    dispatch(hideLoader());
  }, []);

  return (
    <div
      className="d-flex h-dashboard bg"
      style={{ height: "100vh", overflow: "hidden" }}
      id="wrapper"
    >
      <Sidebar />
      <div id="page-content-wrapper" className="height-overflow">
        <Navigation />
        <div className="container-fluid employee-table">
          <div className="row border-bottom text-center mt-3 pb-3">
            <div className="col-2">
              <div className="fw-bolder opacity-75">Id</div>
            </div>
            <div className="col-3">
              <div className="fw-bolder opacity-75">Name</div>
            </div>
            <div className="col-4">
              <div className="fw-bold opacity-50">Email</div>
            </div>
            <div className="col-2">
              <div className="fw-bold opacity-50">Phone</div>
            </div>
            <div className="col-1">
              <div className="fw-bold opacity-50"></div>
            </div>
          </div>
          <div className="d-flex flex-column gap-2">
            {careers.map((career, index) => {
              return (
                <Accordion
                  key={career.id}
                  careerDetail={career}
                  index={index}
                />
              );
            })}
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Careers;
