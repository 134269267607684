const ScreenReducer = (state = 'LOGIN', action) => {
    switch (action.type) {
        case 'UPDATE_SCREEN' :
            return action.payload;
            
        default:
            return state;
    }
}

export default ScreenReducer