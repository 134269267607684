import React from "react";
import User from "../../../images/user.png";
import Logo from "../../../images/logo.png";
import { useDispatch, useSelector } from "react-redux";
import { actionScreenUpdate } from "../../../redux/actions";
import { useNavigate } from "react-router-dom";
function Navigation(props) {
  const dispatch = useDispatch();
  const screen = useSelector((state) => state.actionScreen);
  const navigate = useNavigate();
  const state = props.state || {};
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_FILE_PATH = process.env.REACT_APP_FILE_PATH;
  const { profile_picture = false } = state;
  return (
    <nav className="navbar navbar-expand-lg navbar-light header-navbar">
      <div className="container-fluid">
        <button className="btn" id="sidebarToggle">
          <span className="navbar-toggler-icon" />
        </button>
        <a>
          <img src={Logo} className="mb-logo" alt="logo" />
        </a>

        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {screen === "PROFILE" || screen === "EDIT_PROFILE" ? null : (
            <span className="d-head">dashboard</span>
          )}
          <ul className="navbar-nav ms-auto mt-2 mt-lg-0 align-items-center">
            <li className="nav-item active">
              <div className="dropdown-logout">
                <div
                // style={{
                //   backgroundColor: "#e2e3e5",
                //   padding: "5px",
                //   borderRadius: "5px",
                // }}
                >
                  {profile_picture ? (
                    <img
                      src={
                        REACT_APP_API_URL +
                        REACT_APP_FILE_PATH +
                        profile_picture
                      }
                      style={{
                        width: "30px",
                        height: "30px",
                        borderRadius: "50%",
                        marginRight: "10px",
                      }}
                      alt="icon"
                    />
                  ) : (
                    <img src={User} className="icon" alt="icon" />
                  )}
                  {props.name}
                </div>
                <div
                  className="dropdown-content"
                  onClick={() => {
                    dispatch(actionScreenUpdate("LOGIN"));
                    localStorage.clear();
                    navigate("/");
                    window.location.reload();
                  }}
                >
                  <div>Logout</div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navigation;
