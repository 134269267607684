import React, { useState, useEffect } from 'react'
import Navigation from '../Navigation'
import Footer from '../Footer'
import Sidebar from '../Sidebar'
import { actionScreenUpdate, hideLoader, showLoader } from '../../redux/actions'
import { useDispatch } from "react-redux";
import '../../css/style.css'
import '../../css/bootstrap.min.css'
import AlertModal from '../AlertModel'
import { useAPI } from '../../Api/context'

function AddEmployees() {
  const initialErrors = {
    firstnameError: false,
    lastnameError: false,
    emailError: false,
    departmentError: false,
    statusError: false,
    phoneError: false,
    wageError: false
  }
  const { getDepartments, departmentList, addEmployee } = useAPI();
  const [show, setShow] = useState(false);
  const [firstname, setFirstName] = useState('')
  const [lastname, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [wage, setWage] = useState('')
  const [phone, setPhone] = useState('')
  const [department, setDepartment] = useState('Select Department')
  const [status, setStatus] = useState('Select Status')
  const [error, setError] = useState(initialErrors);
  const [data, setData] = useState({});
  const dispatch = useDispatch();

  const handleChangeFirstName = (e) => { setFirstName(e.target.value.trim()) }

  const handleChangeLastName = (e) => { setLastName(e.target.value.trim()) }

  const handleChangeEmail = (e) => { setEmail(e.target.value.trim()) }

  const handleChangeDepartment = (e) => { setDepartment(e.target.value.trim()) }

  const handleChangeStatus = (e) => { setStatus(e.target.value.trim()) }

  const handleChangePhone = (e) => { setPhone(e.target.value.trim()) }

  const handleChangewage = (e) => { setWage(e.target.value.trim()) }

  function validateEmail(email) {
    const re =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  const clearData = () => {
    setFirstName('')
    setLastName('')
    setEmail('')
    setDepartment('')
    setStatus('')
    setPhone('');
    setWage('')
  }

  useEffect(() => {
    dispatch(showLoader());
    getDepartments();
    dispatch(hideLoader());
    dispatch(actionScreenUpdate("ADD_EMPLOYEES"))
  }, [])

  const AddEmployee = async () => {
    setError(initialErrors)
    if (firstname === '') {
      setError((prevState) => ({
        ...prevState,
        firstnameError: true,
      }));
      return;
    } else if (lastname === '') {
      setError((prevState) => ({
        ...prevState,
        lastnameError: true,
      }));
      return;

    } else if (email === '') {
      setError((prevState) => ({
        ...prevState,
        emailError: true,
      }));
      return;

    } else if (!validateEmail(email)) {
      setError((prevState) => ({
        ...prevState,
        emailError: true,
      }));
      return;
    } else if (department === 'Select Department') {
      setError((prevState) => ({
        ...prevState,
        departmentError: true,
      }));
      return;

    } else if (status === 'Select Status') {
      setError((prevState) => ({
        ...prevState,
        statusError: true,
      }));
      return;
    } else if (phone.length !== 11){
      setError((prevState) => ({
        ...prevState,
        phoneError: true,
      }));
      return;
    } else if (wage.length === 0){
      setError((prevState) => ({
        ...prevState,
        wageError: true,
      }));
      return;
    } else {
    const data = {
      first_name: firstname,
      last_name: lastname,
      email: email,
      designation: '',
      department: department,
      status: status,
      wage: wage,
      phone: phone,
      password: "Ferguson@123"
    }
    clearData()
    const response = addEmployee(data);
    response.then((responseData) => {
      if (responseData===true) {
        setData(data=>({...data,header:"New Employee",body:"Employee Added Succesfully",navigate:"/EmployeesList"}))
        setShow(true)
      } else {
        setData(data=>({...data,header:"Failed!",body:responseData}))
        setShow(true)
      }
    }
    )
    }
  }

  var currencyInput = document.querySelector('input[type="currency"]')
  var currency = 'GBP' 

  onBlur({ target: currencyInput })

  currencyInput && currencyInput.addEventListener('focus', onFocus)
  currencyInput && currencyInput.addEventListener('blur', onBlur)


  function localStringToNumber(s) {
    return Number(String(s).replace(/[^0-9.-]+/g, ""))
  }

  function onFocus(e) {
    var value = e.target && e.target.value;
    e.target.value = value ? localStringToNumber(value) : ''
  }

  function onBlur(e) {
    var value = e.target && e.target.value

    var options = {
      maximumFractionDigits: 2,
      currency: currency,
      style: "currency",
      currencyDisplay: "symbol"
    }

    if (e.target) e.target.value = (value || value === 0)
      ? localStringToNumber(value).toLocaleString(undefined, options)
      : ''
  }

  return (
    <React.Fragment>
      <div className="d-flex h-dashboard bg" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <Navigation />
          <div className="container-fluid">
            <div className="col-md-8">
              <form className="row">
                <div className="col-md-12">
                  <h1 className="page-heading mb-4">Add New Employee </h1>
                </div>
                <div className="col-md-6">
                  <input value={firstname} onChange={handleChangeFirstName} type="text" className="form-control" placeholder="First name" aria-label="First name" />
                  {error.firstnameError ?
                    <div className="add-employee-error">
                      Please enter the first name.
                    </div> : null}
                </div>
                <div className="col-md-6">
                  <input value={lastname} onChange={handleChangeLastName} type="text" className="form-control" placeholder="Last name" aria-label="Last name" />
                  {error.lastnameError ?
                    <div className="add-employee-error">
                      Please enter the last name.
                    </div> : null}
                </div>
                <div className="col-12">
                  <input value={email} onChange={handleChangeEmail} type="email" className="form-control" placeholder="Email Address" id="inputEmail3" />
                  {error.emailError ?
                    <div className="add-employee-error">
                      Please enter a valid email.
                    </div> : null}
                </div>

                <div className="col-md-6">
                  <input value={phone} onChange={handleChangePhone} type="number" className="form-control" placeholder="Phone Number" id="inputphoneNumber" />
                  {error.phoneError ?
                    <div className="add-employee-error">
                      Please enter a valid Number.
                    </div> : null}
                </div>

                <div className="col-md-6">
                  {/* <input value={wage} onChange={handleChangewage} type="text" className="form-control" placeholder="Wage in GBP" id="inputwage" /> */}
                  <input type='currency' value={wage} onChange={handleChangewage} placeholder="Wage (&pound;)" id="inputwage" className="form-control" />
                  {error.wageError ?
                    <div className="add-employee-error">
                      Please enter a valid Wage.
                    </div> : null}
                </div>

                <div className="col-md-6">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Status</label>
                  <select onChange={handleChangeStatus} className="form-select" id="autoSizingSelect">
                    <option value="Select Status" defaultValue>Select Status</option>
                    <option value="Active">Active</option>
                    <option value="Terminated">Terminated</option>
                  </select>
                  {error.statusError ?
                    <div className="add-employee-error">
                      Please select a status
                    </div> : null}
                </div>

                <div className="col-md-6">
                  <label className="visually-hidden" htmlFor="autoSizingSelect">Department</label>
                  <select onChange={handleChangeDepartment} className="form-select" id="autoSizingSelect">

                    <option defaultValue>Assign Role</option>
                    {departmentList.map((item, index) => (
                      <option key={index} value={item.name}>
                        {item.name}
                      </option>
                    ))}
                  </select>

                  {error.departmentError ?
                    <div className="add-employee-error">
                      Please select a department.
                    </div> : null}
                </div>

                <div className="col-md-12">
                  <button onClick={(e) => AddEmployee()} type="button" className="btn btn-primary add" data-bs-toggle="modal" data-bs-target="#exampleModal">ADD</button>
                  <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body mt-4  text-center">
                          <h2>Employee Added Successfully.</h2>
                          <p>Login details has been sent to registered email ID. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <AlertModal data={{ ...data, show, setShow }} />
    </React.Fragment>
  )
}

export default AddEmployees