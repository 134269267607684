export const actionScreenUpdate = (name) => {
    return {
      type: "UPDATE_SCREEN",
      payload: name,
    };
  };

export const showLoader = () => {
    return {
      type: "SHOW_LOADING",
    };
};
  
export const hideLoader = () => {
    return {
      type: "HIDE_LOADING",
    };
};