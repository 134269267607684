import React, { useEffect } from "react";
import Navigation from "../Navigation";
import Sidebar from "../Sidebar";
import Footer from "../Footer";
import { actionScreenUpdate } from "../../redux/actions";
import { useDispatch } from "react-redux";
import { BsFillPeopleFill } from "react-icons/bs";
import { BsFillPersonCheckFill } from "react-icons/bs";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { BsBagFill } from "react-icons/bs";
import Card from "./card";
import { useAPI } from "../../Api/context";
import { useNavigate } from "react-router-dom";
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const COLORS = ["#0088FE", "#00C49F", "#FFBB28"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

function getMonthNumber(monthName) {
  const months = {
    Jan: 0,
    Feb: 1,
    Mar: 2,
    Apr: 3,
    May: 4,
    Jun: 5,
    Jul: 6,
    Aug: 7,
    Sep: 8,
    Oct: 9,
    Nov: 10,
    Dec: 11,
  };
  return months[monthName];
}

const Dashboard = () => {
  const dispatch = useDispatch();
  const {
    getEmployee,
    employees,
    getClient,
    clients,
    getJob,
    jobs,
    getCareers,
    careers,
  } = useAPI();
  const navigate = useNavigate();
  const pending = jobs.filter((each) => each.status === "pending");
  const ongoing = jobs.filter((each) => each.status === "ongoing");
  const complete = jobs.filter((each) => each.status === "complete");
  const data = [
    { name: "Pending", value: pending.length },
    { name: "Completed", value: complete.length },
    { name: "Ongoing", value: ongoing.length },
  ];
  const data2 = [
    {
      Month: "Jan-Feb",
      Jobs: 0,
    },
    {
      Month: "Mar-Apr",
      Jobs: 0,
    },
    {
      Month: "May-Jun",
      Jobs: 0,
    },
    {
      Month: "Jul-Aug",
      Jobs: 0,
    },
    {
      Month: "Sep-Oct",
      Jobs: 0,
    },
    {
      Month: "Nov-Dec",
      Jobs: 0,
    },
  ];
  jobs.forEach((job) => {
    const jobMonth = new Date(job.start).getMonth();
    data2.forEach((monthData) => {
      const monthRange = monthData.Month.split("-");
      const startMonth = getMonthNumber(monthRange[0]);
      const endMonth = getMonthNumber(monthRange[1]);
      if (jobMonth >= startMonth && jobMonth <= endMonth) monthData.Jobs += 1;
    });
  });
  useEffect(() => {
    getEmployee();
    getClient();
    getJob();
    getCareers();
    dispatch(actionScreenUpdate("DASHBOARD"));
  }, []);
  return (
    <React.Fragment>
      <div
        className="d-flex h-dashboard bg"
        id="wrapper"
        style={{ height: "100vh", overflow: "hidden" }}
      >
        <Sidebar />
        <div id="page-content-wrapper" className="height-overflow">
          <Navigation />
          <div
            className="container-fluid"
            id="dashboard"
            // style={{
            //   overflowY: "visible",
            //   overflowX: "hidden",
            //   height: "75vh",
            //   width: "100%",
            // }}
          >
            <div className="col-md-12">
              <div className="row">
                <Card
                  Title={`${employees.length} Employees`}
                  ButtonText="View All"
                  OnClick={() => {
                    navigate("/EmployeesList");
                  }}
                  HasButton2={true}
                  ButtonText2="Add"
                  OnClick2={() => {
                    navigate("/ADD_EMPLOYEES");
                  }}
                  Icon={BsFillPeopleFill}
                />
                <Card
                  Title={`${clients.length} Clients`}
                  ButtonText="View All"
                  OnClick={() => {
                    navigate("/CLIENTS");
                  }}
                  HasButton2={true}
                  ButtonText2="Add"
                  OnClick2={() => {
                    navigate("/ADD_CLIENT");
                  }}
                  Icon={BsFillPersonCheckFill}
                />
                <Card
                  Title={`${jobs.length} Jobs`}
                  ButtonText="View All"
                  OnClick={() => {
                    navigate("/JobScheduler");
                  }}
                  Icon={BsBagFill}
                />
                <Card
                  Title={`${careers.length} Applicants`}
                  ButtonText="View All"
                  OnClick={() => {
                    navigate("/JobApplicants");
                  }}
                  Icon={BsFillPersonVcardFill}
                />
              </div>
              <div className="row flex gap-2 justify-content-start align-items-center m-auto">
                <div
                  className="card shadow"
                  style={{ height: "400px", width: "48.3%" }}
                >
                  <div
                    className="fs-3 text-muted"
                    data-toggle="tooltip"
                    title="Job Status to check if it is pending or ongoing or completed"
                  >
                    Job Status
                  </div>
                  <div className="d-flex gap-1 justify-content-evenly align-items-center h-100">
                    <ResponsiveContainer width="100%" height="100%">
                      <PieChart width={600} height={600}>
                        <Pie
                          data={data}
                          cx="30%"
                          cy="50%"
                          labelLine={true}
                          // label={renderCustomizedLabel}
                          outerRadius={100}
                          fill="#8884d8"
                          dataKey="value"
                          label={true}
                        >
                          {data.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                    <div className="d-flex flex-column align-items-start justify-content-start gap-2">
                      <div className="d-flex gap-2 align-items-center justify-content-start">
                        <div
                          style={{
                            backgroundColor: "#0088FE",
                            width: "15px",
                            height: "15px",
                          }}
                        ></div>
                        Pending
                      </div>
                      <div className="d-flex gap-2 align-items-center justify-content-center">
                        <div
                          style={{
                            backgroundColor: "#FFBB28",
                            width: "15px",
                            height: "15px",
                          }}
                        ></div>
                        Ongoing
                      </div>
                      <div className="d-flex gap-2 align-items-center justify-content-center">
                        <div
                          style={{
                            backgroundColor: "#00C49F",
                            width: "15px",
                            height: "15px",
                          }}
                        ></div>
                        Completed
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="card shadow flex gap-2"
                  style={{ height: "400px", width: "50%" }}
                >
                  <div
                    className="fs-3 text-muted"
                    data-toggle="tooltip"
                    title="Line Chart to visualise the number of jobs which start in range of 2 months"
                  >
                    Jobs
                  </div>
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                      width={500}
                      height={300}
                      data={data2}
                      margin={{
                        top: 5,
                        right: 30,
                        left: 20,
                        bottom: 5,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="Month" />
                      <YAxis yAxisId="left" />
                      <Tooltip />
                      <Legend />
                      <Line
                        yAxisId="left"
                        type="monotone"
                        dataKey="Jobs"
                        stroke="#8884d8"
                        activeDot={{ r: 8 }}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Dashboard;
