import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { BsChevronRight } from "react-icons/bs";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {
  MDBCol,
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCardText,
  MDBCardBody,
  MDBCardImage,
  MDBTypography,
} from "mdb-react-ui-kit";
import user from "../../images/users.png";
import { useAPI } from "../../Api/context";
import { timeSheetComponents } from "../Employee/TimeSheet/utils";
import JobDetails from "../Employee/TimeSheet/JobDetails";

function Accordion(props) {
  const { getJobByEmail } = useAPI();
  const { employeeDetail } = props;
  const [jobs, setJobs] = useState([]);
  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const [setRotate, setRotateState] = useState("accordion__icon");
  const [component, setComponent] = useState(timeSheetComponents.initial);
  const [jobDetails, setJobDetails] = useState({});
  const [activeTab, setActiveTab] = useState("home");
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const REACT_APP_FILE_PATH = process.env.REACT_APP_FILE_PATH;

  const content = useRef(null);

  function toggleAccordion() {
    setActiveState(setActive === "" ? "accordionActive" : "");
    setHeightState(setActive === "accordionActive" ? "0px" : `100%`);
    setRotateState(
      setActive === "accordionActive"
        ? "accordion__icon"
        : "accordion__icon rotate"
    );
  }

  useEffect(() => {
    const job = getJobByEmail(employeeDetail.email);
    job
      .then((data) => {
        setJobs(data);
      })
      .catch((err) => {
        alert(err.message);
      });
  }, []);

  const onDetailClick = (data) => {
    setJobDetails(data);
    setComponent(timeSheetComponents.jobDetails);
  };

  return (
    <div className="accordion__section">
      <div className={`accordion ${setActive}`} onClick={toggleAccordion}>
        <div
          key={employeeDetail.id}
          className={`row border-bottom text-center pt-3 pb-3`}
        >
          <div className="col-2">
            <div className="fw-bolder opacity-75" style={{ color: "#004169" }}>
              {employeeDetail.id}
            </div>
          </div>
          <div className="col-3">
            <div className="fw-bolder opacity-75">
              {employeeDetail.first_name + " " + employeeDetail.last_name}
            </div>
          </div>
          <div className="col-4">
            <div className="fw-bold opacity-50">{employeeDetail.email}</div>
          </div>
          <div className="col-2">
            <div className="fw-bold opacity-50">{employeeDetail.status}</div>
          </div>
          <div className="col-1">
            <BsChevronRight
              className={`${setRotate}`}
              width={10}
              fill={"#777"}
            />
          </div>
        </div>
      </div>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        <Tabs defaultActiveKey="Profile" className="mb-3">
          <Tab tabClassName="Profile" eventKey="Profile" title="Profile">
            <MDBContainer className="py-0">
              <MDBRow className="justify-content-center align-items-center h-100">
                <MDBCol lg="10" className="mb-4 mb-lg-0">
                  <MDBCard className="h-100" style={{ borderRadius: ".5rem" }}>
                    <MDBRow className="g-0">
                      <MDBCol
                        md="4"
                        className="gradient-custom text-center text-white"
                        style={{
                          borderTopLeftRadius: ".5rem",
                          borderBottomLeftRadius: ".5rem",
                        }}
                      >
                        <MDBCardImage
                          src={
                            employeeDetail.profile_picture
                              ? `${
                                  REACT_APP_API_URL +
                                  REACT_APP_FILE_PATH +
                                  employeeDetail.profile_picture
                                }`
                              : user
                          }
                          alt="User"
                          className="my-5 man"
                          style={{
                            width: "170px",
                            height: "170px",
                            border: "3px",
                          }}
                          fluid
                        />
                        <MDBTypography tag="h5" className="mt-0">
                          {employeeDetail.first_name +
                            " " +
                            employeeDetail.last_name}
                        </MDBTypography>
                        <MDBCardText>{employeeDetail.department}</MDBCardText>
                        <div className="d-flex gap-3 justify-content-center">
                          <button
                            className="btn custum-btn-hover mb-4 text-white bg-danger"
                            onClick={() =>
                              props.handleDeleteClick(employeeDetail.id)
                            }
                          >
                            Delete
                          </button>
                          <button
                            className="btn custum-btn-hover mb-4 text-white"
                            onClick={() =>
                              props.handleEditClick(
                                employeeDetail.id,
                                props.index
                              )
                            }
                            style={{ backgroundColor: "#004169" }}
                          >
                            Edit
                          </button>
                        </div>
                      </MDBCol>
                      <MDBCol md="8">
                        <MDBCardBody className="p-4">
                          <MDBTypography tag="h6">Information</MDBTypography>
                          <hr className="mt-0 mb-4" />
                          <MDBRow className="pt-1">
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">Email</MDBTypography>
                              <MDBCardText className="text-muted">
                                {employeeDetail.email}
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">Phone</MDBTypography>
                              <MDBCardText className="text-muted">
                                {employeeDetail.phone_number}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="pt-1">
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">status</MDBTypography>
                              <MDBCardText className="text-muted">
                                {employeeDetail.status}
                              </MDBCardText>
                            </MDBCol>
                            <MDBCol size="6" className="mb-3">
                              <MDBTypography tag="h6">wage</MDBTypography>
                              <MDBCardText className="text-muted">
                                &pound;{employeeDetail.wage}
                              </MDBCardText>
                            </MDBCol>
                          </MDBRow>
                          <MDBRow className="pt-1">
                            {employeeDetail.street_address && (
                              <MDBCol size="6" className="mb-3">
                                <MDBTypography tag="h6">
                                Address
                                </MDBTypography>
                                <MDBCardText className="text-muted">
                                  {employeeDetail.street_address}
                                </MDBCardText>
                              </MDBCol>
                            )}
                            {employeeDetail.city && (
                              <MDBCol size="6" className="mb-3">
                                <MDBTypography tag="h6">City</MDBTypography>
                                <MDBCardText className="text-muted">
                                  {employeeDetail.city}
                                </MDBCardText>
                              </MDBCol>
                            )}
                          </MDBRow>
                          <MDBRow className="pt-1">
                            {employeeDetail.employeeDetail && (
                              <MDBCol size="6" className="mb-3">
                                <MDBTypography tag="h6">State</MDBTypography>
                                <MDBCardText className="text-muted">
                                  {employeeDetail.state}
                                </MDBCardText>
                              </MDBCol>
                            )}
                            {employeeDetail.zip_code && (
                              <MDBCol size="6" className="mb-3">
                                <MDBTypography tag="h6">Zip Code</MDBTypography>
                                <MDBCardText className="text-muted">
                                  {employeeDetail.zip_code}
                                </MDBCardText>
                              </MDBCol>
                            )}
                            {employeeDetail.country && (
                              <MDBCol size="6" className="mb-3">
                                <MDBTypography tag="h6">Country</MDBTypography>
                                <MDBCardText className="text-muted">
                                  {employeeDetail.country}
                                </MDBCardText>
                              </MDBCol>
                            )}
                          </MDBRow>
                        </MDBCardBody>
                      </MDBCol>
                    </MDBRow>
                  </MDBCard>
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </Tab>
          <Tab tabClassName="Jobs" eventKey="Jobs" title="Jobs">
            <div>
              {component.timeSheet && (
                <div>
                  <div className="container ps-4 pe-4 mt-0 border border-2 rounded-1">
                    <div className="row">
                      <div className="col bg-light">
                        <h1 className="text-start page-heading m-3">
                          Today's Job
                        </h1>
                      </div>
                    </div>
                    <div className="row border-bottom text-center mt-3 pb-3">
                      <div className="col-2">
                        <div className="fw-bolder opacity-75">Job Number</div>
                      </div>
                      <div className="col-2">
                        <div className="fw-bolder opacity-75">Title</div>
                      </div>
                      <div className="col-2">
                        <div className="fw-bold opacity-50">Time</div>
                      </div>
                      <div className="col-2">
                        <div className="fw-bold opacity-50">Time Taken</div>
                      </div>
                      <div className="col-2">
                        <div className="fw-bold opacity-50">Details</div>
                      </div>
                      <div className="col-2">
                        <div className="fw-bold opacity-50">Status</div>
                      </div>
                    </div>
                    {jobs.length > 0 &&
                      jobs.map((each) => {
                        const todaysDate = new Date();
                        const jobStartDate = new Date(each.start);
                        const jobEndDate = new Date(each.end);
                        if (
                          todaysDate >= jobStartDate &&
                          todaysDate <= jobEndDate
                        ) {
                          return (
                            <div
                              key={each.id}
                              className={`row border-bottom text-center pt-3 pb-3 ${
                                each.jobType === "Recurring Job"
                                  ? "bg-light"
                                  : ""
                              }`}
                            >
                              <div className="col-2">
                                <div className="fw-bolder opacity-75 text-success">
                                  #{each.jobNumber}
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="fw-bolder opacity-75">
                                  {each.title}
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="fw-bold opacity-50">{`${new Date(
                                  each.start
                                ).toLocaleTimeString()}`}</div>
                              </div>
                              <div className="col-2">
                                <div className="fw-bold opacity-50">
                                  {each.time ? each.time : "00:00:00"}
                                </div>
                              </div>
                              <div className="col-2">
                                <button
                                  onClick={() => onDetailClick(each)}
                                  className="fw-bold opacity-50 btn btn-outline-info text-black border border-info border-2 rounded"
                                >
                                  Details
                                </button>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                {each.status === "pending" && (
                                  <div className="fw-bolder opacity-50">
                                    Pending
                                  </div>
                                )}
                                {each.status === "ongoing" && (
                                  <div className="fw-bolder opacity-50">
                                    Ongoing
                                  </div>
                                )}
                                {each.status === "complete" && (
                                  <div className="fw-bolder opacity-50 text-success">
                                    Completed
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </div>
                  <div className="container ps-4 pe-4 mt-0 border border-2 rounded-1">
                    <div className="row">
                      <div className="col bg-light">
                        <h1 className="text-start page-heading m-3">
                          Previous Job
                        </h1>
                      </div>
                    </div>
                    <div className="row border-bottom text-center mt-3 pb-3">
                      <div className="col-2">
                        <div className="fw-bolder opacity-75">Job Number</div>
                      </div>
                      <div className="col-2">
                        <div className="fw-bolder opacity-75">Title</div>
                      </div>
                      <div className="col-2">
                        <div className="fw-bold opacity-50">Date</div>
                      </div>
                      <div className="col-2">
                        <div className="fw-bold opacity-50">Time Taken</div>
                      </div>
                      <div className="col-2">
                        <div className="fw-bold opacity-50">Details</div>
                      </div>
                      <div className="col-2">
                        <div className="fw-bold opacity-50">Status</div>
                      </div>
                    </div>
                    {jobs.length > 0 &&
                      jobs.map((each) => {
                        const todaysDate = new Date();
                        const jobDate = new Date(each.start);
                        const jobEndDate = new Date(each.end);
                        let timing = `${new Date(
                          each.start
                        ).toLocaleDateString()}`;
                        if (todaysDate > jobDate && todaysDate > jobEndDate) {
                          return (
                            <div
                              key={each.id}
                              className={`row border-bottom text-center pt-3 pb-3 ${
                                each.jobType === "Recurring Job"
                                  ? "bg-light"
                                  : ""
                              }`}
                            >
                              <div className="col-2">
                                <div className="fw-bolder opacity-75 text-success">
                                  #{each.jobNumber}
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="fw-bolder opacity-75">
                                  {each.title}
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="fw-bold opacity-50">
                                  {timing}
                                </div>
                              </div>
                              <div className="col-2">
                                <div className="fw-bold opacity-50">
                                  {each.time ? each.time : "00:00:00"}
                                </div>
                              </div>
                              <div className="col-2">
                                <button
                                  onClick={() => onDetailClick(each)}
                                  className="fw-bold opacity-50 btn btn-outline-info text-black border border-info border-2 rounded"
                                >
                                  Details
                                </button>
                              </div>
                              <div className="col-2 d-flex justify-content-center">
                                {each.status === "pending" && (
                                  <div className="fw-bolder opacity-50">
                                    Pending
                                  </div>
                                )}
                                {each.status === "ongoing" && (
                                  <div className="fw-bolder opacity-50">
                                    Ongoing
                                  </div>
                                )}
                                {each.status === "complete" && (
                                  <div className="fw-bolder opacity-50 text-success">
                                    Completed
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </div>

                  <div className="container ps-4 pe-4 mt-0 border border-2 rounded-1">
                    <div className="row">
                      <div className="col bg-light">
                        <h1 className="text-start page-heading m-3">
                          This Month Job
                        </h1>
                      </div>
                    </div>
                    <div className="row border-bottom text-center mt-3 pb-3">
                      <div className="col-3">
                        <div className="fw-bolder opacity-75">Job Number</div>
                      </div>
                      <div className="col-3">
                        <div className="fw-bolder opacity-75">Title</div>
                      </div>
                      <div className="col-3">
                        <div className="fw-bold opacity-50">Date</div>
                      </div>
                      <div className="col-3">
                        <div className="fw-bold opacity-50">Details</div>
                      </div>
                    </div>
                    {jobs.length > 0 &&
                      jobs.map((each) => {
                        const todaysMonth = new Date().getMonth();
                        const todaysyear = new Date().getFullYear();
                        const jobMonth = new Date(each.start).getMonth();
                        const jobYear = new Date(each.start).getFullYear();
                        let timing = `${new Date(
                          each.start
                        ).toLocaleDateString()}`;
                        if (
                          todaysyear === jobYear &&
                          todaysMonth === jobMonth
                        ) {
                          return (
                            <div
                              key={each.id}
                              className={`row border-bottom text-center pt-3 pb-3 ${
                                each.jobType === "Recurring Job"
                                  ? "bg-light"
                                  : ""
                              }`}
                            >
                              <div className="col-3">
                                <div className="fw-bolder opacity-75 text-success">
                                  #{each.jobNumber}
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="fw-bolder opacity-75">
                                  {each.title}
                                </div>
                              </div>
                              <div className="col-3">
                                <div className="fw-bold opacity-50">
                                  {timing}
                                </div>
                              </div>
                              <div className="col-3">
                                <button
                                  onClick={() => onDetailClick(each)}
                                  className="fw-bold opacity-50 btn btn-outline-info text-black border border-info border-2 rounded"
                                >
                                  Details
                                </button>
                              </div>
                            </div>
                          );
                        } else {
                          return null;
                        }
                      })}
                  </div>
                  <div className="container ps-4 pe-4 mt-0 border border-2 rounded-1">
                    <div className="row">
                      <div className="col bg-light">
                        <h1 className="text-start page-heading m-3">
                          All Jobs
                        </h1>
                      </div>
                    </div>
                    <div className="row border-bottom text-center mt-3 pb-3">
                      <div className="col-3">
                        <div className="fw-bolder opacity-75">Job Number</div>
                      </div>
                      <div className="col-3">
                        <div className="fw-bolder opacity-75">Title</div>
                      </div>
                      <div className="col-3">
                        <div className="fw-bold opacity-50">Date</div>
                      </div>
                      <div className="col-3">
                        <div className="fw-bold opacity-50">Details</div>
                      </div>
                    </div>
                    {jobs.length > 0 &&
                      jobs.map((each) => {
                        let timing = `${new Date(
                          each.start
                        ).toLocaleDateString()}`;
                        return (
                          <div
                            key={each.id}
                            className={`row border-bottom text-center pt-3 pb-3 ${
                              each.jobType === "Recurring Job" ? "bg-light" : ""
                            }`}
                          >
                            <div className="col-3">
                              <div className="fw-bolder opacity-75 text-success">
                                #{each.jobNumber}
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="fw-bolder opacity-75">
                                {each.title}
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="fw-bold opacity-50">{timing}</div>
                            </div>
                            <div className="col-3">
                              <button
                                onClick={() => onDetailClick(each)}
                                className="fw-bold opacity-50 btn btn-outline-info text-black border border-info border-2 rounded"
                              >
                                Details
                              </button>
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              )}
              {component.details && (
                <JobDetails data={{ setComponent, jobDetails }} />
              )}
            </div>
          </Tab>
          <Tab tabClassName="documents" eventKey="documents" title="Documents">
            <div className="container-fluid">
              <div
                className="container Profile "
                style={{ padding: "0px", margin: "0px" }}
              >
                {employeeDetail.nda && (
                  <div
                    className="card border-0 m-5 bg-gray"
                    style={{ backgroundColor: "#004169" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i className="fal fa-file-pdf"></i>
                        </div>
                        <div className="flex-grow-1 ms-3 w-50 text-white">
                          NDA
                        </div>
                        <div className="flex-shrink-0">
                          <a
                            href={
                              REACT_APP_API_URL +
                              REACT_APP_FILE_PATH +
                              employeeDetail.nda
                            }
                            target="_blank"
                            className="btn btn-link text-white"
                          >
                            View
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {employeeDetail.contract && (
                  <div
                    className="card border-0 m-5"
                    style={{ backgroundColor: "#004169" }}
                  >
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-shrink-0">
                          <i className="fal fa-file-pdf"></i>
                        </div>
                        <div className="flex-grow-1 ms-3 w-50 text-white">
                          Contract
                        </div>
                        <div className="flex-shrink-0">
                          <a
                            href={
                              REACT_APP_API_URL +
                              REACT_APP_FILE_PATH +
                              employeeDetail.contract
                            }
                            target="_blank"
                            className="btn btn-link text-white"
                          >
                            View
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}

export default Accordion;
