import React, { useContext, useState, createContext, useEffect } from "react";
import axios from "axios";
import { useDispatch } from "react-redux";
import { actionScreenUpdate, hideLoader, showLoader } from "../redux/actions";
import { useNavigate } from "react-router-dom";
import { formatDate } from "./utils/formateDate";
const APIContext = createContext();

export function APIContextProvider({ children }) {
  const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [departmentList, setDepartmentList] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [clients, setClients] = useState([]);
  const [careers, setCareers] = useState([]);
  const [jobs, setJobs] = useState([]);
  const [showCalender, setShowCalender] = useState(false);
  const addEmployee = async (data) => {
    dispatch(showLoader());
    const response = await axios
      .post(`${REACT_APP_API_URL}employees/add_employee`, data)
      .then(function (response) {
        dispatch(hideLoader());
        return new Promise((resolve) => {
          setTimeout(() => {
            if (response.data.status) {
              resolve(true);
            } else {
              resolve(response.data.message);
            }
          }, 100);
        });
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(err.message);
      });
    return response;
  };

  const getEmployee = async () => {
    dispatch(showLoader());
    await axios
      .get(`${REACT_APP_API_URL}employees/get_employees`)
      .then(function (response) {
        dispatch(hideLoader());
        setEmployees(response.data.list);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err.message);
      });
  };

  const editEmployee = async (data) => {
    dispatch(showLoader());
    const response = axios
      .post(`${REACT_APP_API_URL}employees/edit_employee`, data)
      .then(function (response) {
        dispatch(hideLoader());
        return new Promise((resolve) => {
          setTimeout(() => {
            if (response.data.status) {
              resolve(true);
            } else {
              resolve(response.data.message);
            }
          }, 100);
        });
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(err.message);
      });
    return response;
  };

  const deleteEmployee = async (data) => {
    dispatch(showLoader());
    axios
      .post(`${REACT_APP_API_URL}employees/delete_employee`, data)
      .then(function (response) {
        dispatch(hideLoader());
        setTimeout(() => {
          if (response.data.status) {
            alert("Employee deleted successfully.");
            getEmployee();
          } else {
            alert(response.data.message);
          }
        }, 100);
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(err.message);
      });
  };

  const getEmployeeById = async (id) => {
    return axios.get(
      `${REACT_APP_API_URL}employees/get_employee_by_id?id=${id}`
    );
  };

  const upDateWorkingHours = async (data) => {
    const { id } = data;
    const response = await axios.post(
      `${REACT_APP_API_URL}employees/upDateWorkingHours/id=${id}`,
      data
    );
    getEmployeeById(id);
    return response;
  };

  const updateLastRunDate = async (data) => {
    const { id } = data;
    return await axios.post(
      `${REACT_APP_API_URL}employees/updateLastRunDate/id=${id}`,
      data
    );
  };

  const getDepartments = async () => {
    dispatch(showLoader());
    await axios
      .get(`${REACT_APP_API_URL}departments/get_departments`)
      .then(function (response) {
        dispatch(hideLoader());
        return setTimeout(() => {
          if (response.data.status) {
            setDepartmentList(response.data.list);
          } else {
            alert(response.data.message);
          }
        }, 100);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err.message);
      });
  };

  const addDepartment = async (data) => {
    dispatch(showLoader());
    await axios
      .post(`${REACT_APP_API_URL}departments/add_department`, data)
      .then(function (response) {
        dispatch(hideLoader());
        setTimeout(() => {
          if (response.data.status) {
            getDepartments();
            alert("Department added successfully.");
          } else {
            alert(response.data.message);
          }
        }, 100);
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(err.message);
      });
  };

  const deleteDepartment = async (data) => {
    dispatch(showLoader());
    await axios
      .post(`${REACT_APP_API_URL}departments/delete_department`, data)
      .then(function (response) {
        dispatch(hideLoader());
        setTimeout(() => {
          if (response.data.status) {
            alert("Department deleted successfully.");
            getDepartments();
          } else {
            alert(response.data.message);
          }
        }, 100);
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(err.message);
      });
  };

  const editDepartment = async (data) => {
    dispatch(showLoader());
    const response = axios
      .post(`${REACT_APP_API_URL}departments/edit_department`, data)
      .then(function (response) {
        dispatch(hideLoader());
        return new Promise((resolve) => {
          setTimeout(() => {
            if (response.data.status) {
              resolve(true);
            } else {
              resolve(response.data.message);
            }
          }, 100);
        });
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(err.message);
      });
    return response;
  };

  const getClient = async () => {
    dispatch(showLoader());
    axios
      .get(`${REACT_APP_API_URL}clients/get_clients`)
      .then(function (response) {
        dispatch(hideLoader());
        setClients(response.data.list);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err.message);
      });
  };

  const addClient = async (data) => {
    return axios.post(`${REACT_APP_API_URL}clients/add_client`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  };

  const deleteClient = async (id) => {
    const data = await axios.delete(`${REACT_APP_API_URL}clients/${id}`);
    getClient();
    return data;
  };

  const editClient = async (data, id) => {
    const res = await axios.put(
      `${REACT_APP_API_URL}clients/update_client/${id}`,
      data,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    getClient();
    return res;
  };

  const loginApi = async (data) => {
    dispatch(showLoader());
    const response = await axios
      .post(`${REACT_APP_API_URL}login/login`, data)
      .then(function (response) {
        dispatch(hideLoader());
        const { status, role, id, adminId } = response.data;
        return new Promise((resolve) => {
          setTimeout(() => {
            if (status) {
              localStorage.setItem("role", role);
              localStorage.setItem("id", id);
              if (role === "admin") {
                console.log(response.data);
                localStorage.setItem("adminId", adminId);
                dispatch(actionScreenUpdate("DASHBOARD"));
                navigate("/DASHBOARD");
              } else {
                dispatch(actionScreenUpdate("EMPLOYEE_PROFILE"));
                navigate("/EmployeeProfile");
              }
              resolve(true);
            } else {
              resolve(response.data.message);
            }
          }, 100);
        });
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(err.message);
      });
    return response;
  };

  const getJob = async () => {
    dispatch(showLoader());
    await axios
      .get(`${REACT_APP_API_URL}jobs/get_all_jobs`)
      .then((response) => {
        const data = response.data.list.map((data) => {
          const startDate = new Date(data.start);
          const endDate = new Date(data.end);
          return {
            ...data,
            display: "block",
            editable: true,
            start: startDate,
            end: endDate,
            textColor: "black",
            borderColor: "#004169 !important",
          };
        });
        setJobs(data);
        setShowCalender(true);
      })
      .catch((err) => {
        alert(err.message);
      });
    dispatch(hideLoader());
  };

  const getJobByEmail = async (email) => {
    return await axios
      .get(`${REACT_APP_API_URL}jobs/get_job_by_email/${email}`)
      .then((response) => {
        const data = response.data.list.map((data) => {
          const startDate = new Date(data.start);
          let endDate = null;
          if (data.end) endDate = new Date(data.end);
          return {
            ...data,
            display: "block",
            editable: true,
            start: startDate,
            end: endDate,
            textColor: "black",
            borderColor: "#004169 !important",
            jobFor: data.jobFor.split(","),
          };
        });
        return data;
      })
      .catch((err) => {
        return err;
      });
  };

  const addJob = async (data) => {
    dispatch(showLoader());
    const response = await axios
      .post(`${REACT_APP_API_URL}jobs/add_job`, data)
      .then(function (response) {
        dispatch(hideLoader());
        const { status } = response.data;
        return new Promise((resolve) => {
          setTimeout(() => {
            if (status) {
              resolve(true);
            } else {
              resolve(response.data.message);
            }
          }, 100);
        });
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(err.message);
      });
    return response;
  };

  const deleteJob = async (data, deleteRecursive) => {
    dispatch(showLoader());
    const response = await axios
      .delete(
        `${REACT_APP_API_URL}jobs/delete_job/${data}?deleteRecursive=${deleteRecursive}`
      )
      .then(function (response) {
        dispatch(hideLoader());
        const { status } = response.data;
        return new Promise((resolve) => {
          setTimeout(() => {
            if (status) {
              resolve(true);
            } else {
              resolve(response.data.message);
            }
          }, 100);
        });
      })
      .catch((err) => {
        dispatch(hideLoader());
        alert(err.message);
      });
    return response;
  };

  const updateStatus = async (data) => {
    const Response = await axios.put(
      `${REACT_APP_API_URL}jobs/updateStatus/${data.id}`,
      data
    );
    return Response;
  };
  const updateJobTime = async (data) => {
    const Response = await axios.put(
      `${REACT_APP_API_URL}jobs/updateJobTime/${data.id}`,
      data
    );
    return Response;
  };

  const addNote = async (data) => {
    const Response = await axios.put(
      `${REACT_APP_API_URL}jobs/addNote/${data.id}`,
      data
    );
    return Response;
  };

  const getCareers = async () => {
    dispatch(showLoader());
    axios
      .get(`${REACT_APP_API_URL}careers/get_career`)
      .then(function (response) {
        console.log(response);
        dispatch(hideLoader());
        setCareers(response.data.list);
      })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err.message);
      });
  };

  return (
    <APIContext.Provider
      value={{
        departmentList,
        setDepartmentList,
        addDepartment,
        deleteDepartment,
        getDepartments,
        editDepartment,

        employees,
        setEmployees,
        addEmployee,
        getEmployee,
        editEmployee,
        deleteEmployee,
        getEmployeeById,
        upDateWorkingHours,
        updateLastRunDate,

        getClient,
        clients,
        setClients,
        addClient,
        deleteClient,
        editClient,

        getJob,
        showCalender,
        setShowCalender,
        jobs,
        setJobs,
        deleteJob,
        addJob,
        getJobByEmail,
        updateStatus,
        addNote,
        updateJobTime,

        loginApi,

        careers,
        getCareers,
      }}
    >
      {children}
    </APIContext.Provider>
  );
}

export function useAPI() {
  const context = useContext(APIContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
