import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';

const LocationSearchInput = (props) => {
  const [address, setAddress] = useState('');
  const SettingAddress=props.setAddress

  const handleChange = (newAddress) => {
    setAddress(newAddress);
  };

  const handleSelect = (selectedAddress) => {
    SettingAddress(selectedAddress);
    setAddress(selectedAddress)
    geocodeByAddress(selectedAddress)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log('Success', latLng))
      .catch((error) => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div>
          <input
            {...getInputProps({
              placeholder: 'Address',
              className: 'location-search-input form-control'
            })}
          />
          <div className="autocomplete-dropdown-container ">
           <div className='list-group'>
            {loading ? <div className='d-flex justify-content-center align-items-center'><Spinner/></div>:
             suggestions.map((suggestion) => {
              const className = 'list-group-item';
              const style = suggestion.active
                ? {
                    backgroundColor: '#004169',
                    cursor: 'pointer',
                    fontWeight: 'bold',
                  }
                : {
                    backgroundColor: '#ccc',
                    cursor: 'pointer',
                  };;
              return (
                <div
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span >{suggestion.description}</span>
                </div>
              );
            })}
           </div>
          </div>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default LocationSearchInput;
