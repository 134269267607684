import React, { useState, useEffect } from "react";
import Login from "./components/Login";
import AddEmployees from "./components/AddEmployees";
import Dashboard from "./components/Dashboard";
import EmployeesList from "./components/EmployeesList";
import ClientsList from "./components/ClientsList";
import AddDepartment from "./components/AddDepartment";
import Profile from "./components/Profile";
import AddClients from "./components/AddClients";
import EditProfile from "./components/EditProfile";
import Loader from "../src/components/Loader";
import EmployeeProfile from "./components/Employee/Profile";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  MemoryRouter,
  HashRouter,
} from "react-router-dom";
import EditEmployeeProfile from "./components/Employee/EditProfile";
import { useSelector, useDispatch } from "react-redux";
import { actionScreenUpdate } from "./redux/actions";
import "./App.css";
import EmployeeTimeSheet from "./components/Employee/TimeSheet";
import EmployeePayRoll from "./components/Employee/PayRoll";
import EmployeeDocuments from "./components/Employee/Documents";
import JobScheduler from "./components/JobScheduler";
import Careers from "./components/Careers";
import Attendance from "./components/Attendance";
import Holiday from "./components/Holiday";
import Award from "./components/Award";
import Notice from "./components/Notice";
import Expense from "./components/Expense";
import Payslips from "./components/Payslips";
import Training from "./components/Training";
import Tasks from "./components/Tasks";
import Support from "./components/Support";

function App() {
  const [logged, setLogged] = React.useState(false);
  const screen = useSelector((state) => state.actionScreen);
  const role = localStorage.getItem("role");
  const dispatch = useDispatch();
  useEffect(() => {
    setLogged(localStorage.getItem("logged"));
    if (localStorage.getItem("logged") !== "true") {
      dispatch(actionScreenUpdate("LOGIN"));
    }
  }, [logged]);

  return (
    <div className="App">
      <Loader />
      <React.StrictMode>
        <Routes>
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Dashboard />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/DASHBOARD"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <AddEmployees />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/ADD_EMPLOYEES"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <AddClients />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/ADD_CLIENT"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <ClientsList />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/CLIENTS"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <AddDepartment />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/DEPARTMENT"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <EmployeesList />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/EmployeesList"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <JobScheduler />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/JobScheduler"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Careers />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/JobApplicants"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Attendance />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/Attendance"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Holiday />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/Holiday"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Award />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/Award"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Notice />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/Notice"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Expense />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/Expense"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Payslips />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/Payslips"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Training />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/Training"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Tasks />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/Tasks"
          />
          <Route
            element={
              localStorage.getItem("role") === "admin" ? (
                <Support />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/Support"
          />

          <Route
            element={
              localStorage.getItem("role") === "employee" ? (
                <EmployeeProfile />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/EmployeeProfile"
          />
          <Route
            element={
              localStorage.getItem("role") === "employee" ? (
                <Profile />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/Profile"
          />
          <Route
            element={
              localStorage.getItem("role") === "employee" ? (
                <EditEmployeeProfile />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/EditEmployeeProfile"
          />
          <Route
            element={
              localStorage.getItem("role") === "employee" ? (
                <EmployeeTimeSheet />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/EmployeeTimeSheet"
          />
          <Route
            element={
              localStorage.getItem("role") === "employee" ? (
                <EmployeePayRoll />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/EmployeePayRoll"
          />
          <Route
            element={
              localStorage.getItem("role") === "employee" ? (
                <EmployeeDocuments />
              ) : (
                <Navigate replace to={"/"} />
              )
            }
            path="/EmployeeDocuments"
          />
          <Route element={<Login />} path="/" />
        </Routes>
      </React.StrictMode>
    </div>
  );
}

export default App;
