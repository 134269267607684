import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Map from "./index";
import { Col, Container, Row } from "react-bootstrap";

const GoogleMap = (props) => {
  const { showMap, setShowMap, setAddress, address } = props.data;
  const handleClose = () => {
    setShowMap(false);
  };
  return (
    <div>
      <Modal
        show={showMap}
        onHide={handleClose}
        fullscreen={true}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Address: {address}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex">
          <Map setAddress={setAddress} address={address} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default GoogleMap;
