import React, { useState, useEffect } from 'react'
import Navigation from '../Navigation'
import Footer from '../Footer'
import Sidebar from '../Sidebar'
import axios from 'axios'
import { actionScreenUpdate, hideLoader, showLoader } from '../../redux/actions'
import { useDispatch, useSelector } from "react-redux";
import '../../css/style.css'
import '../../css/bootstrap.min.css'
import EditDepartment from '../EditDepartment'
import { Modal, Button } from 'react-bootstrap';
import { useAPI } from "../../Api/context";

function AddDepartment() {

  const [department, setDepartment] = useState('')

  const [error, setError] = useState({ departmentError: false });
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const [selPosition, setSelPosition] = useState(0)

  const { addDepartment, deleteDepartment, getDepartments, departmentList  } = useAPI();

  const handleChangeDepartment = (e) => { setDepartment(e.target.value.trim()) }

  const handleEditClick = (employeeId, index) => {
    setSelPosition(index)
    handleShow()
  };

  useEffect(() => {
    getDepartments()
    dispatch(actionScreenUpdate("DEPARTMENT"))
  }, [])



  const handleDeleteClick = (departmentId) => {
    const data = { id: departmentId }
    deleteDepartment(data)
  };

  const AddNewDepartment = () => {
    setError({
      departmentError: false
    })

    if (department === '') {
      setError((prevState) => ({
        ...prevState,
        departmentError: true,
      }));
      return;
    } else {
      const data = { department_name: department }
      setDepartment('')
      addDepartment(data);
    }
  }

  return (
    <React.Fragment>
      <div className="d-flex h-dashboard bg" id="wrapper">
        <Sidebar />
        <div id="page-content-wrapper">
          <Navigation />
          <div className="container-fluid">
            <div className="col-md-10">
              <form className="row">
                <div className="col-md-12">
                  <h1 className="page-heading mb-4">Add New Department</h1>
                </div>
                <div className="col-md-6">
                  <input value={department} onChange={handleChangeDepartment} type="text" className="form-control" placeholder="Department Name" aria-label="Department name" />
                  {error.departmentError ?
                    <div className="add-employee-error">
                      Please enter department name.
                    </div> : null}
                </div>

                <div className="col-md-6">
                  <button onClick={(e) => AddNewDepartment()} type="button" className="btn btn-primary add" data-bs-toggle="modal" data-bs-target="#exampleModal">ADD</button>
                  <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body mt-4  text-center">
                          <h2>Employee Added Successfully.</h2>
                          <p>Login details has been sent to registered email ID. </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="container-fluid employee-table">
                  <div className="table-responsive dashboard-table mt-4">
                    <table className="table table-light">
                      <thead className="shadow">
                        <tr>
                          <th>ID</th>
                          <th>Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>

                      <tbody>
                        {departmentList.map((department, index) => (
                          <tr key={index}>
                            <td>
                              {index + 1}
                            </td>
                            <td>{department.name}</td>

                            <div  >
                              <button type="button" className="btn btn-primary custom-btn me-2" onClick={() => handleEditClick(department.id, index)} >Edit</button>
                              <button type="button" className="btn btn-danger custom-btn" onClick={() => handleDeleteClick(department.id)} >Delete</button>
                            </div>
                          </tr>

                        ))}

                        <Modal show={show} onHide={handleClose}>
                          <Modal.Header closeButton>
                            <Modal.Title>
                              Edit Department
                            </Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <EditDepartment pos={selPosition} departmentLists={departmentList} popup={handleClose} refresh={getDepartments} />
                          </Modal.Body>

                        </Modal>
                      </tbody>

                    </table>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default AddDepartment