const moment = require('moment');
export const JOB_COMPONENT = {
  initial: {
    calendar: true,
    newJob: false,
    newBasicTask: false,
    jobDetails:false,
  },
  newJob: {
    calendar: false,
    newJob: true,
    newBasicTask: false,
    jobDetails:false,
  },
  jobDetails: {
    jobDetails:true,
    calendar: false,
    newJob: false,
    newBasicTask: false,
  },
  newBasicTask: {
    calendar: false,
    newJob: false,
    newBasicTask: true,
    jobDetails:false,
  },
};


export function createSchedule(jobDetails, daysOfWeek) {
  const start = moment(jobDetails.start);
  const end = moment(jobDetails.end);
  let jobNumber=jobDetails.jobNumber;
  const result = [];
  if (!start.isValid() || !end.isValid()) {
    throw new Error('Invalid date format');
  }

  if (!start.isSameOrBefore(end)) {
    throw new Error('Start date must be before or equal to end date');
  }

  while (start.isSameOrBefore(end)) {
    const dayOfWeek = start.format('dddd').toLowerCase();
    if (daysOfWeek[dayOfWeek]) {
      const startTime = moment(start,'M/D/YYYY h:mmA').format('YYYY-MM-DDTHH:mm');
      const endTime = moment(start,'M/D/YYYY h:mmA').set({ hour: end.hour(), minute: end.minute() }).format('YYYY-MM-DDTHH:mm');
      result.push({...jobDetails, start: startTime, end: endTime,jobNumber:jobNumber });
      const number =parseInt(jobNumber) + 1;
      jobNumber = number < 10 ? `00${number}` : number < 100 ? `0${number}` : number;
    }
    start.add(1, 'day');
  }

  return result;
}
