import React from "react";
import { BsFillPeopleFill } from "react-icons/bs";

const Card = ({
  Icon = BsFillPeopleFill,
  Title = "",
  ButtonText = "",
  OnClick = () => {},
  HasButton2=false,
  ButtonText2="",
  OnClick2=()=>{}
}) => {
  return (
    <div class="col-xl-3 col-lg-6 mb-4">
      <div class="card mb-4 mb-xl-0 h-100 shadow">
        <div class="card-body bg-info">
          <div class="row d-flex justify-content-between">
            <div class="col-auto">
              <div class="icon-shape bg-white text-white rounded-circle shadow">
                <Icon size={50} color="#004169" style={{ padding: "10px" }} />
              </div>
            </div>
            <div class="col-auto">
              <h5 class="text-sm text-white mb-0">{Title}</h5>
              <p class="mt-3 mb-0 d-flex gap-1 text-muted text-sm">
                <button
                  style={{ backgroundColor: "#004169" }}
                  className="btn text-white"
                  onClick={OnClick}
                >
                  {ButtonText}
                </button>
                {HasButton2 && <button
                  style={{ backgroundColor: "#004169" }}
                  className="btn text-white"
                  onClick={OnClick2}
                >
                  {ButtonText2}
                </button>}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Card;
